import { getItemFromLocalStorage } from "utility/browser-storage/local-storage";

const _getHost = () => {
  // TODO: pass this in via env variable
  return window.location.hostname === "localhost"
    ? "http://localhost:8000"
    : `https://${window.location.host}`;
};

const _getDefaultHeaders = () => {
  return {
    Authorization: `JWT ${getItemFromLocalStorage("token")}`,
    "Content-Type": "application/json"
  };
};

// This is only for legacy calls: use explicit http methods going forward
export const makeRequest = (url: string, params: any) => {
  return fetch(`${_getHost()}${url}`, params);
};

export const _makeRequest = async (url: string, params: any) => {
  const result = await fetch(`${_getHost()}${url}`, params);
  if (!result.ok) {
    throw new Error(
      `HTTP Error for (${url}) ${result.status}: ${result.statusText}`
    );
  }
  const json = await result.json();
  return json;
};

export const getHttp = async (url: string, additionalParams?: any) => {
  const defaultParams = { method: "GET", headers: _getDefaultHeaders() };
  const params =
    additionalParams != undefined
      ? { ...defaultParams, ...additionalParams }
      : defaultParams;
  return await _makeRequest(url, params);
};

export const postHttp = async (
  url: string,
  body: any,
  additionalParams?: any
) => {
  const defaultParams = {
    method: "POST",
    body: JSON.stringify(body),
    headers: _getDefaultHeaders()
  };
  const params =
    additionalParams != undefined
      ? { ...defaultParams, ...additionalParams }
      : defaultParams;
  return await _makeRequest(url, params);
};

export const putHttp = async (
  url: string,
  body: any,
  additionalParams?: any
) => {
  const defaultParams = {
    method: "PUT",
    body: JSON.stringify(body),
    headers: _getDefaultHeaders()
  };
  const params =
    additionalParams != undefined
      ? { ...defaultParams, ...additionalParams }
      : defaultParams;
  return await _makeRequest(url, params);
};

export const deleteHttp = async (url: string, additionalParams?: any) => {
  const defaultParams = { method: "DELETE", headers: _getDefaultHeaders() };
  const params =
    additionalParams != undefined
      ? { ...defaultParams, ...additionalParams }
      : defaultParams;
  return await _makeRequest(url, params);
};
