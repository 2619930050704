import styled from "@emotion/styled";
import { Colors, FontSize } from "../../../css/styles.modulecss";

interface FlagBoxParams {
  position: string;
  widthPct: number;
  heightOffset: number;
  expanded: boolean;
}

export const FlagBox = styled.div<FlagBoxParams>`
  position: absolute;
  margin: 0;
  padding: 5px;
  top: ${(props) => props.heightOffset * 20}%;
  border: 1px solid black;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 5px 5px 20px 2px rgba(0, 0, 0, 0.1);
  left: ${(props) => props.position}%;
  transition: all 1s;
  overflow: ${(props) => (props.expanded ? "scroll" : "hidden")};
  height: 46%;
  max-height: ${(props) => (props.expanded ? "50%" : "150px")};
  min-height: 150px;
  max-width: ${(props) => props.widthPct}%;
  min-width: 100px;
  z-index: ${(props) => (props.expanded ? "1" : "auto")};
  background-color: ${Colors.white};
`;

interface FlagPoleParams {
  position: string;
  heightOffset: number;
}

export const FlagPole = styled.div<FlagPoleParams>`
  position: absolute;
  margin: 0;
  height: ${(props) => 100 - props.heightOffset * 20}%;
  width: 0;
  border-left: 1px solid black;
  left: ${(props) => props.position}%;
  transition: all 0.2s;
  overflow: hidden;
  box-shadow: 5px 5px 20px 2px rgba(0, 0, 0, 0.1);
`;

export const FlagHeader = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: ${FontSize.smallText};
`;

export const FlagDate = styled.div`
  color: ${Colors.black};
  font-size: 0.75rem;
`;

export const FlagSource = styled.span`
  color: ${Colors.slate_gray};
  font-size: 0.75rem;
`;

export const FlagTotalArticles = styled.div`
  color: ${Colors.black};
  font-size: 0.75rem;
  cursor: pointer;
`;
