import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import moment from "moment";
import { Logos } from "./Logos";
import {
  SearchListCard,
  LoaderContainer,
  SearchLists,
  SearchList,
  SearchIMG,
  SearchContent,
  ContentHead,
  DateTime,
  News,
  NoResultOnCanvas,
  NoResultFound,
  ContentContainer
} from "./SearchResults.modulecss";
import LoadingOrError from "components/reusable/LoadingOrError";
import { ArticleData } from "services/article-service";

import { Colors } from "css/styles.modulecss";
import { SearchBox } from "../Dashboard/SearchBox";

interface SearchResultsProps {
  onSelectArticle: (article: ArticleData) => void;
  canvasArticleList: ArticleData[] | null;
  articlesList: ArticleData[];
  fetching: boolean;
  fetchingDataErrMsg?: string;
  isSearchPerformed: boolean;
  onSearch: (query: string, useBing: boolean) => void;
  searchValue: string;
  setSearchValue: (value: string | ((prevState: string) => string)) => void;
}

export const SearchResults = (props: SearchResultsProps) => {
  const { articlesList } = props;

  const selectArticle = (item: ArticleData) => {
    if (
      props.canvasArticleList?.find(
        (obj) => obj.external_id === item.external_id
      ) === undefined
    ) {
      props.onSelectArticle(item);
    }
  };

  return (
    <SearchListCard>
      <SearchBox
        onSearch={props.onSearch}
        searchValue={props.searchValue}
        setSearchValue={props.setSearchValue}
      />
      {props.fetching || props.fetchingDataErrMsg ? (
        <LoaderContainer>
          <LoadingOrError
            loadingMsg={props.fetching ? "Loading Articles..." : undefined}
            errMsg={props.fetchingDataErrMsg}
          />
        </LoaderContainer>
      ) : (
        <Droppable droppableId="searchResults">
          {(provided) => (
            <SearchLists
              {...provided.droppableProps}
              ref={provided.innerRef}
              aria-setsize={articlesList.length}
            >
              {articlesList &&
                articlesList.length !== 0 &&
                articlesList.map((item: ArticleData, index: any) => {
                  return props.canvasArticleList?.find(
                    (obj) => obj.external_id === item.external_id
                  ) !== undefined ? (
                    <Draggable
                      aria-posinset={index}
                      aria-label="Draggable article stub"
                      aria-disabled="true"
                      key={item.external_id}
                      draggableId={item.external_id}
                      index={index}
                      isDragDisabled={true}
                    >
                      {(provided) => (
                        <SearchList
                          key={item.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            background: Colors.dark_grey,
                            opacity: 0.6,
                            ...provided.draggableProps.style
                          }}
                        >
                          <ContentHead
                            onClick={async () => {
                              await selectArticle(item);
                            }}
                          >
                            {item.title || ""}
                          </ContentHead>
                          <SearchContent
                            onDoubleClick={async () => {
                              await selectArticle(item);
                            }}
                          >
                            <SearchIMG>
                              <Logos
                                source_name={
                                  item.source ? item.source.name : ""
                                }
                                provider_display_name={
                                  item.source &&
                                  item.source.provider_display_name
                                    ? item.source.provider_display_name
                                    : ""
                                }
                              />
                            </SearchIMG>

                            <ContentContainer>
                              <DateTime>
                                {item.published_at &&
                                  moment(item.published_at)
                                    .utc(true)
                                    .local()
                                    .format("MMM. DD, YYYY h:mm A")}
                              </DateTime>
                              <News>{item.description || ""}</News>
                            </ContentContainer>
                          </SearchContent>
                        </SearchList>
                      )}
                    </Draggable>
                  ) : (
                    <Draggable
                      aria-posinset={index}
                      aria-label="Draggable article stub"
                      aria-disabled="false"
                      key={item.external_id}
                      draggableId={item.external_id}
                      index={index}
                    >
                      {(provided) => (
                        <SearchList
                          key={item.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ContentHead
                            onClick={async () => {
                              await selectArticle(item);
                            }}
                          >
                            {item.title || ""}
                          </ContentHead>
                          <SearchContent
                            onDoubleClick={async () => {
                              await selectArticle(item);
                            }}
                          >
                            <SearchIMG>
                              <Logos
                                source_name={
                                  item.source ? item.source.name : ""
                                }
                                provider_display_name={
                                  item.source &&
                                  item.source.provider_display_name
                                    ? item.source.provider_display_name
                                    : ""
                                }
                              />
                            </SearchIMG>

                            <ContentContainer>
                              <DateTime>
                                {item.published_at &&
                                  moment(item.published_at)
                                    .utc(true)
                                    .local()
                                    .format("MMM. DD, YYYY h:mm A")}
                              </DateTime>
                              <News>{item.description || ""}</News>
                            </ContentContainer>
                          </SearchContent>
                        </SearchList>
                      )}
                    </Draggable>
                  );
                })}

              {props.isSearchPerformed && articlesList.length === 0 ? (
                <NoResultOnCanvas>
                  No results found :( <br></br>{" "}
                  <NoResultFound>
                    We didn't find any articles for your seach.
                  </NoResultFound>
                </NoResultOnCanvas>
              ) : null}
              {provided.placeholder}
            </SearchLists>
          )}
        </Droppable>
      )}
    </SearchListCard>
  );
};
