// Clear all items from browser local storage
export const clearLocalStorage = () => {
  localStorage.clear();
};

// Get Item from browser local storage
export const getItemFromLocalStorage = (
  key: string,
  decode: boolean = false
) => {
  const keyData = localStorage.getItem(key);
  if (keyData) {
    if (decode) {
      return JSON.parse(
        Buffer.from(JSON.parse(keyData), "base64").toString("ascii")
      );
    } else {
      try {
        return JSON.parse(keyData);
      } catch (e) {
        // For some reason we had a non-JSON-encoded localstorage value--:shrug:
        return keyData;
      }
    }
  }
  return null;
};

// Set Item to browser local storage
export const setItemToLocalStorage = (
  key: string,
  data: any,
  encode: boolean = false
) => {
  if (encode) {
    localStorage.setItem(
      key,
      JSON.stringify(
        Buffer.from(JSON.stringify(data), "ascii").toString("base64")
      )
    );
  } else {
    localStorage.setItem(key, JSON.stringify(data));
  }
};

// Remove Item from browser local storage
export const removeItemFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};
