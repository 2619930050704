import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const Colors = {
  white: "#ffffff",
  red: "#f44336",
  error: "#ff0000;",
  black: "#000000",
  light_grey: "#c7c7d1",
  dark_grey: "#525252",
  success: "#357935",
  dark_cream: "#debd26",
  blue: "#2206f7",
  bright_blue: "#0264c9",
  dark_cream_hover: "#ffcf00",
  grey: "#dfdfdf",
  yellow: "#FFF35B",
  yellow_hover: "#FFF35B",
  light_with_dark: "#000000a0",
  slate_gray: "#424242",
  icon_gold:
    "invert(77%) sepia(72%) saturate(505%) hue-rotate(358deg) brightness(91%) contrast(90%)",
  timeline_yellow: "#FFF35B",
  magenta: "#8300b3",
  sky_blue: "#2bc8f3",
  dark_blue: "#323261",
  login_shadow: "rgba(3,16,26,0.5)",
  bluish_purple: "#4a44e6",
  dark_purple: "#0c006a",
  background_purple: "#f2efff",
  light_purple: "#e2dfff",
  purple: "#7e48e6",
  bright_purple: "#c86bfa",
  purple_hover: "#e0aafc",
  dark_type: "#5b5b60",
  purple_grey: "#c8c5d8",
  timeline_purple: "#c5baf5"
};

export const FontFamily = {
  fontSans: "'Source Sans Pro', sans-serif", // default, generally won't be specified explicitly
  fontRoboto: "'Roboto', sans-serif",
  fontColfax: "Colfax"
};

export const FontSize = {
  title: "32px",
  heading: "24px",
  largeText: "18px",
  smallText: "16px"
};

// Defined common custom element css

const Title = styled.h1`
  margin: 0;
  font-weight: 600;
  text-transform: uppercase;
  line-height: normal;
  font-size: ${FontSize.heading};
  font-family: ${FontFamily.fontSans};
  letter-spacing: 6px;
  @media (max-width: 767px) {
    font-size: 28px;
    letter-spacing: 4px;
  }
`;

export const Subtitle = styled.p`
  margin: 0;
  font-size: ${FontSize.largeText};
  color: ${Colors.slate_gray};
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 25px;
  @media (max-width: 767px) {
    font-size: ${FontSize.smallText};
    margin-top: 5px;
    margin-bottom: 20px;
  }
`;

const HasError = styled.div`
  margin-top: 10px;
  color: ${Colors.error};
`;

const HasSuccess = styled.div`
  margin-top: 10px;
  color: ${Colors.success};
`;

const StyleLink = styled(Link)`
  color: ${Colors.white};
  text-decoration: none;
  font-size: ${FontSize.smallText};
  :hover {
    color: ${Colors.dark_cream};
  }
`;

const LinksWrapper = styled.li`
  display: flex;
  color: ${Colors.white};
  padding: 0 15px;
  border-right: 1px solid ${Colors.white};
  :last-child {
    border-right: 0;
  }
`;

export const CommonButton = styled.button`
  display: flex;
  width: 146px;
  height: 53px;
  background-color: ${Colors.black};
  color: ${Colors.white};
  border: 0;
  padding: 0;
  text-align: center;
  font-size: ${FontSize.smallText};
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 4px;
  font-family: ${FontFamily.fontRoboto};
  text-transform: uppercase;
  letter-spacing: 1.43px;
  padding: 5px;
  :hover {
    background-color: ${Colors.yellow};
  }
  :focus,
  :active {
    outline: 0;
  }
  @media (max-width: 1400px) {
    height: 40px;
    letter-spacing: 1px;
  }
  @media (max-width: 1199px) {
    font-size: 13px;
  }
`;

export const CommonHtmlElement = {
  Title,
  Subtitle,
  CommonButton,
  HasError,
  HasSuccess,
  StyleLink,
  LinksWrapper
};
