import styled from "@emotion/styled";
import { Colors, FontSize } from "../../../css/styles.modulecss";

export const ClearCanvasModalLayout = styled.div`
  background-color: grey;
  margin: 0;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 3;
`;

export const ClearCanvasModalContainer = styled.div`
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-color: ${Colors.dark_grey};
  border-style: solid;
  border-width: 1px;
  background: ${Colors.white};
  border-radius: 15px;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 2;
`;

export const ClearCanvasHeader = styled.div`
  font-size: ${FontSize.heading};
  text-align: center;
  margin: 3vh;
`;

export const LayoutButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;

export const ClearCanvasButton = styled.button`
  height: 30px;
  width: 150px;
  font-size: 14px;
  padding: 5px;
  margin: 5px;
  color: ${Colors.dark_grey};
  border-style: solid;
  border-width: 1px;
  background-color: ${Colors.white};
  border-radius: 15px;
  z-index: 2;
  cursor: pointer;
`;
