import styled from "@emotion/styled";
import { Colors, FontSize } from "../../../css/styles.modulecss";

interface TimelineColumnParams {
  columnPosition: string;
  columnHeight: string;
  columnWidth: string;
}

interface TickLabelParams {
  tickWidth: string;
  opacity: number;
}

export const TimelineWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
`;

export const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 200px);
  min-height: 500px;
  min-width: 900px;
  border-radius: 15px;
  padding: 20px;
`;

export const TimelineColumn = styled.div<TimelineColumnParams>`
  position: absolute;
  bottom: 0;
  height: ${(props) => props.columnHeight};
  width: ${(props) => props.columnWidth};
  background-color: ${Colors.timeline_purple};
  margin: 0;
  padding: 0;
  transition: all 1s;
  transform: translateX(${(props) => props.columnPosition});
  max-height: 50%;
  z-index: 1;
`;

export const EarliestBucketWarningContainer = styled.div`
  position: absolute;
  bottom: 5px;
  translate: 5px;
  z-index: 2;
`;

export const EarliestBucketWarningIcon = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid red;
  cursor: default;
  font-weight: bold;
`;

export const EarliestBucketWarning = styled.div`
  position: absolute;
  bottom: 20px;
  translate: 20px;
  border-radius: 10px;
  width: 200px;
  padding: 5px;
  background-color: white;
  font-size: 12px;
`;

export const Tick = styled.div<TickLabelParams>`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.tickWidth};
  height: 5px;
  border-left: 1px solid black;
  opacity: ${(props) => props.opacity};
  transition: opacity 2s;
`;

export const TimelineAxis = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: flex-end;
  height: 100%;
  width: 100%;
  border-bottom: 1px solid black;
  overflow: hidden;
`;

export const TickContainer = styled.div`
  display: flex;
  width: 100%;
  height: 5px;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Label = styled.div<TickLabelParams>`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.tickWidth};
  text-align: center;
  padding: 0px;
  margin: 0px;
  white-space: pre;
  opacity: ${(props) => props.opacity};
  transition: opacity 2s;
  font-size: 1em;
  font-weight: 500;
`;

export const ZoomPeripheralsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-width: 900px;
  justify-content: space-between;
  padding: 10px 15px;
`;

export const SearchContainer = styled.div`
  display: block;
  justify-content: center;
  width: 31.4%;
  padding: 0 15px;
  @media (max-width: 599px) {
    width: 100%;
    margintop: 5px;
  }
`;

export const ZoomLevelContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 1.1rem;
  align-self: center;
  justify-content: center;
  align-items: flex-start;
`;

export const TotalArticlesLabel = styled.div`
  font-size: 0.9rem;
`;

export const SliderContainer = styled.div`
  position: relative;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30px;
`;

export const SliderRail = styled.div`
  width: 100%;
  height: 15px;
  border-bottom: 2px solid black;
  overflow: hidden;
`;

interface SliderRangeProps {
  rangeStartPct: number;
  rangeEndPct: number;
}

export const SliderRangeWidget = styled.div<SliderRangeProps>`
  position: absolute;
  width: ${(props) => props.rangeEndPct - props.rangeStartPct}%;
  height: 30px;
  overflow: hidden;
`;

export const SliderRangeBar = styled.div`
  width: 100%;
  margin-top: 10px;
  height: 10px;
  background-color: ${Colors.slate_gray};
`;

interface SliderPaddleProps {
  isLeft?: boolean;
}

export const SliderPaddleContainer = styled.div`
  position: absolute;
  height: 30px;
  width: 100%;
  top: 0%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SliderHandle = styled.div`
  width: 60px;
  height: 60%;
  background-color: ${Colors.dark_purple};
  cursor: grabbing;
`;

interface PaddleLabelProps {
  startPct: number;
}
export const SliderPaddleLabel = styled.div<PaddleLabelProps>`
  position: relative;
  margin-top: 10px;
  left: ${(props) =>
    props.startPct > 3 ? props.startPct - 3 : props.startPct}%;
`;

export const SliderPaddle = styled.div<SliderPaddleProps>`
  width: 10px;
  height: 100%;
  background-color: ${Colors.dark_grey};
`;

export const EmptyStateText = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${FontSize.heading};
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingIndicator = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: ${Colors.timeline_purple};
    animation: loading 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes loading {
    0% {
      top: 8px;
      height: 64px;
    }
    50%,
    100% {
      top: 24px;
      height: 32px;
    }
  }
`;
