import * as Yup from "yup";

export const SignupSchema = Yup.object().shape({
  first_name: Yup.string().required("Firstname is required").max(60),
  last_name: Yup.string().required("Lastname is required").max(60),
  username: Yup.string().required("Username is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .min(6, "Too short. Must be minimum of 6 characters")
    .required("Password is required"),
  passwordConfirm: Yup.string()
    .oneOf(
      [Yup.ref("password"), undefined],
      "The password & its confirm password does not match"
    )
    .required("Confirm password is required"),
  register_as: Yup.string().required(
    "Please register as an educator or student"
  ),
  termsConditionsConfirm: Yup.boolean().oneOf(
    [true],
    "Please confirm that you agree to our terms and privacy policy"
  )
});
