import React from "react";
import { Route, Redirect, BrowserRouter, Switch } from "react-router-dom";

import { RouterAuth } from "./authed/RouterAuth";
import { RouterPreauth } from "./preauth/RouterPreauth";

import { UserStateContext, UserDispatchContext } from "../contexts/UserContext";
import { tryLoginWithLocalToken } from "../services/login-service";
import { Typography } from "@mui/material";

export const MuckApp = () => {
  const userDispatch = React.useContext(UserDispatchContext);
  const userState = React.useContext(UserStateContext);

  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    const onMount = async () => {
      await tryLoginWithLocalToken(userDispatch);
      setIsLoading(false);
    };
    onMount();
  }, []);

  // return (
  //   <div
  //     style={{
  //       height: window.innerHeight,
  //       display: "flex",
  //       flexDirection: "column",
  //       justifyContent: "center",
  //       alignItems: "center"
  //     }}
  //   >
  //     <Typography>
  //       Mucktracker is under construction!
  //     </Typography>
  //     <Typography>
  //       For questions, please e-mail us at{" "}
  //       <a href="mailto:info@mucktracker.com">info@mucktracker.com</a>
  //     </Typography>
  //   </div>
  // );

  if (window.innerWidth < 600) {
    return (
      <div
        style={{
          height: window.innerHeight,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Typography>
          Mucktracker isn't currently available on mobile. Please try on
          desktop.
        </Typography>
        <Typography>
          For support, please e-mail us at{" "}
          <a href="mailto:info@mucktracker.com">info@mucktracker.com</a>
        </Typography>
      </div>
    );
  }

  // eslint-disable-next-line react/prop-types
  const PrivateRoute = ({ children, ...rest }): JSX.Element => {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          userState.id ? (
            children
          ) : isLoading ? (
            <div />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  };

  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute path="/user">
          <RouterAuth />
        </PrivateRoute>
        <RouterPreauth />
      </Switch>
    </BrowserRouter>
  );
};
