import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import VennDiagram from "./Diagrams/VennDiagram.png";
import TwoCol from "./Diagrams/TwoCol.png";
import Matrix from "./Diagrams/Matrix.png";
import ThreeCol from "./Diagrams/ThreeCol.png";
import { ClearCanvasModal } from "./ClearCanvasModal";
import { Logos } from "./Logos";

import {
  ArticleData,
  postCanvas,
  removeCanvasArticle,
  updateCanvas,
  Canvas,
  clearCanvas,
  REACTIONS
} from "services/article-service";
import {
  NoResultOnCanvasContainer,
  CardContainer,
  LogoContainer,
  CardContent,
  ContentHead,
  DateTime,
  News,
  NoResultOnCanvas,
  CanvasContainer,
  OrganizerList,
  DeleteButton,
  HeaderContent,
  FormContainer,
  MatrixContainer,
  MiddleLabelContainer,
  VennContainer,
  TwoColContainer,
  ThreeColContainer,
  canvasContentStyles,
  OrganizerListContainer,
  DiagramImage,
  DiagramImageContainer,
  ExpandArticleButton,
  ButtonsContainer,
  ButtonText,
  getCanvasLayoutWidth,
  getCanvasLayoutHeight,
  ClearCanvasButton,
  LayoutButtonContainer,
  LeftCardBlock,
  ReactionContainer
} from "./CanvasComponent.modulecss";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import moment from "moment";
import {
  ButtonContainer,
  CanvasButtonContainer,
  CanvasHeaderButtons,
  OrganizerButton
} from "./Dashboard.modulecss";
import DeleteIcon from "@mui/icons-material/Delete";
interface CanvasComponentState {
  organizerPopup: boolean;
  organizer: string;
  label1: string | null;
  label2: string | null;
  label3: string | null;
  label4: string | null;
  canvas_id: string | null;
  organizerInput: string;
}

interface CanvasComponentProps {
  onSelectArticle: (article: ArticleData) => void;
  canvasArticles: ArticleData[] | null;
  canvas: Canvas | null;
  deleteCanvasArticle: (any) => void;
  refreshArticles: () => void;
  organizerOptionsModel: boolean;
  clearCanvasModal: boolean;
  handleOrganizerModelChange: () => void;
  handleCanvasClearModal: () => void;
  updateArticlePosition: (
    articleId: number,
    newXPct: number,
    newYPct: number
  ) => void;
  isReadOnly?: boolean;
  articleList: ArticleData[] | null;
}

export const CanvasComponent = (props: CanvasComponentProps) => {
  const classes = canvasContentStyles();
  const [state, setState] = useState<CanvasComponentState>({
    canvas_id: null,
    organizerPopup: false,
    organizer: "NONE",
    label1: null,
    label2: null,
    label3: null,
    label4: null,
    organizerInput: "NONE"
  });

  const canvasButtons = (
    <ButtonContainer>
      <CanvasButtonContainer>
        <OrganizerButton onClick={props.handleOrganizerModelChange}>
          Canvas Organizers
        </OrganizerButton>
        <DeleteIcon fontSize="large" onClick={props.handleCanvasClearModal}>
          Clear Canvas
        </DeleteIcon>
      </CanvasButtonContainer>
    </ButtonContainer>
  );

  const handleChange = async (event) => {
    const newOrganizer = event.target.value;
    setState({
      ...state,
      organizerInput: newOrganizer
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await postCanvas();
      setState({
        ...state,
        canvas_id: result.data.id,
        organizer: result.data.organizer,
        label1: result.data.label1,
        label2: result.data.label2,
        label3: result.data.label3,
        label4: result.data.label4,
        organizerInput: result.data.organizer
      });
    };
    fetchData();
  }, []);

  const handleClearCanvas = async () => {
    if (state.canvas_id) {
      await clearCanvas(state.canvas_id);
      props.refreshArticles();
      setState({
        ...state,
        label1: "",
        label2: "",
        label3: "",
        label4: ""
      });
    }
  };

  const handleLabelChange = async (event, labelNumber) => {
    const data = {
      organizer: state.organizer,
      label1: state.label1,
      label2: state.label2,
      label3: state.label3,
      label4: state.label4
    };
    switch (labelNumber) {
      case 1:
        setState({ ...state, label1: event.target.value });
        data.label1 = event.target.value;
        break;
      case 2:
        setState({ ...state, label2: event.target.value });
        data.label2 = event.target.value;
        break;
      case 3:
        setState({ ...state, label3: event.target.value });
        data.label3 = event.target.value;
        break;
      case 4:
        setState({ ...state, label4: event.target.value });
        data.label4 = event.target.value;
        break;
    }
    await updateCanvas(data);
  };

  const cancelLayoutChange = async () => {
    setState({
      ...state,
      organizerInput: state.organizer
    });
    props.handleOrganizerModelChange();
  };

  const handleUpdateLayout = async () => {
    const data = {
      organizer: state.organizerInput,
      label1: "",
      label2: "",
      label3: "",
      label4: ""
    };
    await updateCanvas(data);
    setState({
      ...state,
      ...data,
      organizer: state.organizerInput
    });
    props.handleOrganizerModelChange();
  };

  const deleteHandler = async (article: ArticleData) => {
    if (props.canvas && props.canvas.id) {
      const data = {
        canvas: props.canvas.id,
        external_id: article.external_id
      };
      await removeCanvasArticle(data);
    }
    props.deleteCanvasArticle(article.external_id);
  };

  const handleDrag = async (
    e: DraggableEvent,
    ui: DraggableData,
    article: ArticleData
  ) => {
    const width = getCanvasLayoutWidth();
    const height = getCanvasLayoutHeight();
    const initialArticle = props.canvasArticles?.find((a: ArticleData) => {
      return a.id === article.id;
    });
    if (width !== undefined && height !== undefined && initialArticle) {
      const newX = ui.lastX;
      const newY = ui.lastY;
      const newXPct = newX / width;
      const newYPct = newY / height;
      await props.updateArticlePosition(article.id, newXPct, newYPct);
    }
  };

  const width = getCanvasLayoutWidth();
  const height = getCanvasLayoutHeight();
  const articleCards =
    props.canvasArticles !== null && props.canvasArticles.length !== 0
      ? props.canvasArticles.map((item: ArticleData, index: number) => {
          const x_pct =
            item.canvas_x_pct == 0 ? 0.02 * index : item.canvas_x_pct;
          const y_pct =
            item.canvas_y_pct == 0 ? 0.04 * index : item.canvas_y_pct;
          const x_pos = x_pct * width;
          const y_pos = y_pct * height;
          const reaction = REACTIONS.find((r) => r.value === item.reaction);
          return (
            <Draggable
              bounds="parent"
              key={item.external_id}
              position={{ x: x_pos, y: y_pos }}
              disabled={props.isReadOnly}
              onStop={(e: DraggableEvent, ui: DraggableData) => {
                handleDrag(e, ui, item);
              }}
              onDrag={(e: DraggableEvent, ui: DraggableData) => {
                console.log(`x: ${ui.lastX} y: ${ui.lastX}`);
              }}
              handle=".handle"
            >
              <CardContainer
                style={{
                  cursor: props.isReadOnly ? "default" : "grabbing"
                }}
              >
                <LeftCardBlock>
                  <div>
                    {item.num_summaries > 0 || item.num_tags > 0 ? (
                      <EditIcon
                        fontSize="small"
                        onClick={() => {
                          props.onSelectArticle(item);
                        }}
                      />
                    ) : null}
                  </div>
                  <LogoContainer className="handle">
                    <Logos
                      source_name={item.source ? item.source.name : ""}
                      provider_display_name={
                        item.source && item.source.provider_display_name
                          ? item.source.provider_display_name
                          : ""
                      }
                    />
                  </LogoContainer>
                  <ReactionContainer>
                    {reaction && reaction.value !== "NONE" && reaction.emoji
                      ? reaction.emoji
                      : null}
                  </ReactionContainer>
                </LeftCardBlock>

                <CardContent>
                  <HeaderContent>
                    <ContentHead
                      onClick={() => {
                        props.onSelectArticle(item);
                      }}
                    >
                      {item.title || ""}
                    </ContentHead>
                  </HeaderContent>
                  <DateTime className="handle">
                    {item.published_at &&
                      moment(item.published_at)
                        .utc(true)
                        .local()
                        .format("MMM. DD, h:mm A")}
                  </DateTime>
                  <News className="handle">{item.description || ""}</News>
                </CardContent>
                <ButtonsContainer className="handle">
                  {props.isReadOnly ? (
                    <div />
                  ) : (
                    <DeleteButton
                      onClick={() => {
                        deleteHandler(item);
                      }}
                      aria-label="Delete Article Stub from Canvas"
                    >
                      <ButtonText>{String.fromCharCode(10006)}</ButtonText>
                    </DeleteButton>
                  )}
                  <ExpandArticleButton
                    onClick={() => {
                      props.onSelectArticle(item);
                    }}
                    aria-label="Expand Article Info"
                  >
                    <ButtonText>{"+"}</ButtonText>
                  </ExpandArticleButton>
                </ButtonsContainer>
              </CardContainer>
            </Draggable>
          );
        })
      : null;

  return (
    <>
      <CanvasContainer id="CanvasContainer">
        {props.canvasArticles === null ||
        props.canvas === null ||
        props.articleList === null ? (
          <div />
        ) : props.canvasArticles.length === 0 &&
          props.articleList.length !== 0 ? (
          <NoResultOnCanvasContainer>
            <NoResultOnCanvas>
              Drag news stubs to bring them onto this canvas
            </NoResultOnCanvas>
          </NoResultOnCanvasContainer>
        ) : (
          articleCards
        )}
        {state.organizerInput == "MATRIX" ? (
          <>
            <DiagramImageContainer>
              <DiagramImage src={Matrix} />
            </DiagramImageContainer>
            <MatrixContainer>
              <TextField
                id="outlined-basic"
                placeholder="Type Label Here"
                value={state.label1}
                onChange={(e) => {
                  handleLabelChange(e, 1);
                }}
                inputProps={{ style: { textAlign: "center" } }}
                classes={{ root: classes.textField }}
                size="medium"
                aria-label="canvasMatrixLabel1"
              />
              <MiddleLabelContainer>
                <TextField
                  id="outlined-basic"
                  placeholder="Type Label Here"
                  value={state.label2}
                  onChange={(e) => {
                    handleLabelChange(e, 2);
                  }}
                  classes={{ root: classes.textField }}
                  inputProps={{ style: { textAlign: "center" } }}
                  size="medium"
                  aria-label="canvasMatrixLabel2"
                />
                <TextField
                  id="outlined-basic"
                  placeholder="Type Label Here"
                  value={state.label3}
                  onChange={(e) => {
                    handleLabelChange(e, 3);
                  }}
                  inputProps={{ style: { textAlign: "center" } }}
                  classes={{ root: classes.textField }}
                  size="medium"
                  aria-label="canvasMatrixLabel3"
                />
              </MiddleLabelContainer>
              <TextField
                id="outlined-basic"
                placeholder="Type Label Here"
                value={state.label4}
                onChange={(e) => {
                  handleLabelChange(e, 4);
                }}
                inputProps={{ style: { textAlign: "center" } }}
                classes={{ root: classes.textField }}
                size="medium"
                aria-label="canvasMatrixLabel4"
              />
            </MatrixContainer>
          </>
        ) : null}
        {state.organizerInput == "VENN" ? (
          <>
            <DiagramImageContainer>
              <DiagramImage src={VennDiagram} />
            </DiagramImageContainer>
            <VennContainer>
              <TextField
                id="outlined-basic"
                placeholder="Type Label Here"
                value={state.label1}
                onChange={(e) => {
                  handleLabelChange(e, 1);
                }}
                classes={{ root: classes.textField }}
                size="medium"
                aria-label="canvasMatrixLabel1"
              />
              <TextField
                id="outlined-basic"
                placeholder="Type Label Here"
                value={state.label2}
                onChange={(e) => {
                  handleLabelChange(e, 2);
                }}
                classes={{ root: classes.textField }}
                size="medium"
                aria-label="canvasMatrixLabel2"
              />
              <TextField
                id="outlined-basic"
                placeholder="Type Label Here"
                value={state.label3}
                onChange={(e) => {
                  handleLabelChange(e, 3);
                }}
                classes={{ root: classes.textField }}
                size="medium"
                aria-label="canvasMatrixLabel3"
              />
            </VennContainer>
          </>
        ) : null}
        {state.organizerInput == "TWOCOL" ? (
          <>
            <DiagramImageContainer>
              <DiagramImage src={TwoCol} />
            </DiagramImageContainer>
            <TwoColContainer>
              <TextField
                id="outlined-basic"
                placeholder="Type Label Here"
                value={state.label1}
                onChange={(e) => {
                  handleLabelChange(e, 1);
                }}
                classes={{ root: classes.textField }}
                size="medium"
                aria-label="canvasMatrixLabel1"
              />
              <TextField
                id="outlined-basic"
                placeholder="Type Label Here"
                value={state.label2}
                onChange={(e) => {
                  handleLabelChange(e, 2);
                }}
                classes={{ root: classes.textField }}
                size="medium"
                aria-label="canvasMatrixLabel2"
              />
            </TwoColContainer>
          </>
        ) : null}
        {state.organizerInput == "THREECOL" ? (
          <>
            <DiagramImageContainer>
              <DiagramImage src={ThreeCol} />
            </DiagramImageContainer>
            <ThreeColContainer>
              <TextField
                id="outlined-basic"
                placeholder="Type Label Here"
                value={state.label1}
                onChange={(e) => {
                  handleLabelChange(e, 1);
                }}
                inputProps={{ style: { textAlign: "center" } }}
                classes={{ root: classes.textField }}
                size="medium"
                aria-label="canvasMatrixLabel1"
              />
              <TextField
                id="outlined-basic"
                placeholder="Type Label Here"
                value={state.label2}
                onChange={(e) => {
                  handleLabelChange(e, 2);
                }}
                inputProps={{ style: { textAlign: "center" } }}
                classes={{ root: classes.textField }}
                size="medium"
                aria-label="canvasMatrixLabel2"
              />

              <TextField
                id="outlined-basic"
                placeholder="Type Label Here"
                value={state.label3}
                onChange={(e) => {
                  handleLabelChange(e, 3);
                }}
                inputProps={{ style: { textAlign: "center" } }}
                classes={{ root: classes.textField }}
                size="medium"
                aria-label="canvasMatrixLabel3"
              />
            </ThreeColContainer>
          </>
        ) : null}

        {props.organizerOptionsModel ? (
          <OrganizerListContainer>
            <OrganizerList>
              <FormContainer>
                <FormControl component="fieldset">
                  <HeaderContent>
                    <FormLabel component="legend" id="canvasOrganizerLabel">
                      Choose a Canvas Organizer
                    </FormLabel>
                  </HeaderContent>
                  <RadioGroup
                    name="organizers"
                    value={state.organizerInput}
                    onChange={handleChange}
                    aria-labelledby="canvasOrganizerLabel"
                  >
                    <FormControlLabel
                      value="NONE"
                      control={<Radio />}
                      label="None"
                    />
                    <FormControlLabel
                      value="MATRIX"
                      control={<Radio />}
                      label="Matrix Diagram"
                    />
                    <FormControlLabel
                      value="VENN"
                      control={<Radio />}
                      label="Venn Diagram"
                    />
                    <FormControlLabel
                      value="TWOCOL"
                      control={<Radio />}
                      label="Two Column Diagram"
                    />
                    <FormControlLabel
                      value="THREECOL"
                      control={<Radio />}
                      label="Three Column Diagram"
                    />
                  </RadioGroup>
                </FormControl>
                <LayoutButtonContainer>
                  <ClearCanvasButton onClick={handleUpdateLayout}>
                    Confirm
                  </ClearCanvasButton>
                  <ClearCanvasButton onClick={cancelLayoutChange}>
                    Cancel
                  </ClearCanvasButton>
                </LayoutButtonContainer>
              </FormContainer>
            </OrganizerList>
          </OrganizerListContainer>
        ) : null}
        {props.clearCanvasModal ? (
          <ClearCanvasModal
            handleClearCanvas={handleClearCanvas}
            handleCloseModal={props.handleCanvasClearModal}
          />
        ) : null}
        <CanvasHeaderButtons>{canvasButtons}</CanvasHeaderButtons>
      </CanvasContainer>
    </>
  );
};
