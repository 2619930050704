import React from "react";
import { Formik } from "formik";
import { Bounce } from "react-activity";

import TextField from "@mui/material/TextField";
import { Redirect } from "react-router-dom";
import { withStyles } from "mui-styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

import { UserStateContext, UserDispatchContext } from "contexts/UserContext";

import { signIn, getRememberMeValues } from "../../../services/login-service";

import { LogInSchema } from "../../../utility/validation-schema/LoginSchema";

import { CommonHtmlElement, Colors } from "../../../css/styles.modulecss";

import { ColorLogoTitle } from "components/reusable/ColorLogo";

import {
  loginFormStyles,
  loginHelperTextStyles,
  FormWrapper,
  CheckSpan,
  CheckboxRow,
  SignupLink,
  ButtonRow,
  HeadingWrapper,
  MainContent,
  ForgotText,
  ForgotLink
} from "./Login.modulecss";
interface LoginFormValues {
  username: string;
  password: string;
  rememberMeChecked: boolean;
}

// const RememberMeCheckbox = withStyles({
//   root: {
//     color: Colors.black,
//     "&$checked": {
//       color: Colors.yellow
//     }
//   },
//   checked: {}
// })((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export const Login = () => {
  const userDispatch = React.useContext(UserDispatchContext);
  const userState = React.useContext(UserStateContext);
  const classes = loginFormStyles();
  const helperTestClasses = loginHelperTextStyles();

  const [errorMessage, setErrorMessage] = React.useState("");

  React.useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  }, [errorMessage]);

  const onSubmitLoginHandler = async (
    creds: LoginFormValues,
    resetForm: () => void
  ) => {
    try {
      // TODO: move error code handling into error context or a thrown error
      const userResponse = await signIn(userDispatch, creds);

      if (userResponse && userResponse.non_field_errors) {
        setErrorMessage(
          "Sorry, we couldn't find that username and password..."
        );
        resetForm();
      }
    } catch (e) {
      resetForm();
      setErrorMessage("Sorry, we couldn't find that username and password");
    }
  };

  const rememberMeValues = getRememberMeValues();

  if (userState.id) {
    return <Redirect to="/user/dashboard" />;
  }

  return (
    <>
      <HeadingWrapper>
        <ColorLogoTitle />
        <MainContent>Engaging with the news in context</MainContent>
        <CommonHtmlElement.Subtitle style={{ fontWeight: 600 }}>
          Welcome back! Please login to your account.
        </CommonHtmlElement.Subtitle>
      </HeadingWrapper>
      <FormWrapper>
        <Formik
          initialValues={{
            username: (rememberMeValues && rememberMeValues.username) || "",
            password: (rememberMeValues && rememberMeValues.password) || "",
            rememberMeChecked:
              rememberMeValues && rememberMeValues.rememberMeChecked
          }}
          validationSchema={LogInSchema}
          onSubmit={(values, { resetForm }) => {
            onSubmitLoginHandler(values, resetForm);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                type="text"
                id="username-muck"
                label="Username"
                name="username"
                className={classes.textField}
                error={errors.username && touched.username ? true : false}
                helperText={
                  errors.username && touched.username && errors.username
                }
                FormHelperTextProps={{ classes: helperTestClasses }}
                fullWidth={true}
                autoComplete="off"
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                margin="normal"
                InputProps={{
                  className: classes.multilineColor
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                    error: classes.erroredLabel,
                    focused: classes.focusedLabel
                  }
                }}
              />
              <TextField
                type="password"
                id="password-muck"
                label="Password"
                name="password"
                error={errors.password && touched.password ? true : false}
                helperText={
                  errors.password && touched.password && errors.password
                }
                FormHelperTextProps={{ classes: helperTestClasses }}
                fullWidth={true}
                autoComplete="off"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                margin="normal"
                InputProps={{
                  className: classes.multilineColor
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                    error: classes.erroredLabel,
                    focused: classes.focusedLabel
                  }
                }}
              />
              <CheckboxRow>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.rememberMeChecked}
                        onChange={handleChange}
                        name="rememberMeChecked"
                      />
                    }
                    label={<CheckSpan>Remember me</CheckSpan>}
                  />
                </FormGroup>
                {/* <ForgotLink to={"/login"}>Forgot Password?</ForgotLink> */}
              </CheckboxRow>
              {errorMessage && (
                <CommonHtmlElement.HasError
                  style={{ marginTop: 10 }}
                  role="alert"
                >
                  {errorMessage}
                </CommonHtmlElement.HasError>
              )}
              <ButtonRow>
                <Button
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  <span>Login</span>
                  <Bounce color={Colors.black} animating={isSubmitting} />
                </Button>
              </ButtonRow>
              <SignupLink to={"/signup"}>
                First visit? Create an account.
              </SignupLink>
              <ForgotText>
                Forgotten password? Email us at{" "}
                <ForgotLink href="mailto:support@mucktracker.com">
                  <a href="mailto:support@mucktracker.com">
                    support@mucktracker.com
                  </a>
                </ForgotLink>
              </ForgotText>
            </form>
          )}
        </Formik>
      </FormWrapper>
    </>
  );
};

export default Login;
