import styled from "@emotion/styled";
import { Colors, FontFamily, FontSize } from "../../../css/styles.modulecss";
import { makeStyles } from "mui-styles";

// Material-UI Styles
export const canvasContentStyles = makeStyles(() => ({
  textField: {
    margin: "10px",
    width: "150px",
    backgroundColor: Colors.light_purple,
    fontSize: FontSize.largeText,
    borderColor: Colors.dark_grey,
    borderStyle: "solid",
    borderWidth: "1px",
    padding: "10px",
    zIndex: 1
  }
}));

const CARD_HEIGHT = 155;
const CARD_WIDTH = 285;
const CARD_CONTENT_WIDTH = 170;

export const getCanvasLayoutWidth = () => {
  return (
    // @ts-ignore
    document?.getElementById("CanvasContainer")?.offsetWidth - CARD_WIDTH ?? 0
  );
};
export const getCanvasLayoutHeight = () => {
  return (
    // @ts-ignore
    document?.getElementById("CanvasContainer")?.offsetHeight - CARD_HEIGHT ?? 0
  );
};

export const CanvasContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  background-color: ${Colors.background_purple};
`;

export const NoResultOnCanvasContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const NoResultOnCanvas = styled.div`
  padding: 3vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 30%;
  width: 60%;
  transform: translate(-50%, -50%);
  font-size: ${FontSize.title};
  border-color: ${Colors.slate_gray};
  border-style: solid;
  border-width: 1px;
  background-color: ${Colors.white};
  text-align: center;
  z-index: 2;
`;

export const CardContainer = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  border-color: ${Colors.dark_grey};
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  height: ${CARD_HEIGHT}px;
  width: ${CARD_WIDTH}px;
  background-color: ${Colors.white};
`;

export const LeftCardBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: ${Colors.black};
`;
export const ReactionContainer = styled.div`
  height: 1.5em;
  margin-left: 0.2em;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0px;
`;

export const ContentHead = styled.div`
  flex-wrap: wrap;
  height: 40px;
  line-height: 20px;
  width: ${CARD_CONTENT_WIDTH}px;
  font-size: ${FontSize.smallText};
  color: ${Colors.black};
  font-weight: bold;
  margin-top: 8px;
  font-family: ${FontFamily.fontRoboto};
  letter-spacing: 0.3px;
  @media (max-width: 1199px) {
    font-size: 22px;
    line-height: 28px;
  }
  @media (max-width: 599px) {
    font-size: ${FontSize.smallText};
    line-height: 23px;
  }
  :hover {
    color: ${Colors.blue};
    text-decoration: underline;
  }
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
`;

export const DateTime = styled.p`
  font-size: ${FontSize.smallText};
  color: ${Colors.black};
  font-weight: normal;
  margin: 0;
  font-family: ${FontFamily.fontRoboto};
  letter-spacing: 0.2px;
  @media (max-width: 1199px) {
    ${FontSize.smallText};
  }
  @media (max-width: 599px) {
    font-size: 14px;
  }
`;

export const News = styled.p`
  flex-wrap: wrap;
  width: ${CARD_CONTENT_WIDTH}px;
  font-size: ${FontSize.smallText};
  color: ${Colors.black};
  font-weight: normal;
  height: 61px;
  line-height: 20px;
  margin: 0px;
  font-family: ${FontFamily.fontRoboto};
  letter-spacing: 0.2px;
  @media (max-width: 1199px) {
    font-size: ${FontSize.smallText};
  }
  @media (max-width: 599px) {
    font-size: 14px;
    font-family: ${FontFamily.fontSans};
  }
  overflow: hidden;
  white-space: normal;
`;

export const ArticleLink = styled.a`
  font-size: ${FontSize.smallText};
  color: ${Colors.black};
  display: flex;
  align-self: flex-end;
  margin-top: 5px;
  border-bottom: 1px solid #000;
  text-decoration: none;
`;

export const OrganizerButton = styled.button`
  height: 30px;
  width: 125px;
  font-size: 14px;
  padding: 5px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-color: ${Colors.dark_grey};
  border-style: solid;
  border-width: 1px;
  background-color: ${Colors.white};
  border-radius: 15px;
  z-index: 2;
`;

export const OrganizerListContainer = styled.div`
  background-color: grey;
  margin: 0;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: rgba(128, 128, 128, 0.8);
`;

export const OrganizerList = styled.div`
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: row;
  padding: 15px;
  border-color: ${Colors.dark_grey};
  border-style: solid;
  border-width: 1px;
  background: rgba(255, 255, 255, 1);
  border-radius: 15px;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 2;
`;

export const DiagramImage = styled.img`
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  z-index: 0;
`;

export const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  height: 22.5px;
  width: 22.5px;
  font-size: 1.2rem;
  background-color: ${Colors.black};
  color: ${Colors.white};
  border: 1px solid ${Colors.black};
  font-family: ${FontFamily.fontRoboto};
  border-radius: 50%;
  cursor: pointer;
`;
export const ExpandArticleButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  height: 22.5px;
  width: 22.5px;
  font-size: 1.5rem;
  background-color: ${Colors.black};
  color: ${Colors.white};
  border: 1px solid ${Colors.black};
  font-family: ${FontFamily.fontRoboto};
  border-radius: 50%;
  cursor: pointer;
`;

export const ExitCanvasButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  height: 20px;
  width: 20px;
  background-color: ${Colors.black};
  color: ${Colors.white};
  border: 1px solid ${Colors.black};
  font-family: ${FontFamily.fontRoboto};
  font-size: 1rem;
  border-radius: 50%;
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const LayoutButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 80%;
`;

export const ClearCanvasButton = styled.button`
  height: 30px;
  width: 150px;
  font-size: 14px;
  padding: 5px;
  margin: 5px;
  border-color: ${Colors.dark_grey};
  border-style: solid;
  border-width: 1px;
  background-color: ${Colors.white};
  border-radius: 15px;
  z-index: 2;
  cursor: pointer;
`;

export const MatrixContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const TopLabelContainer = styled.div`
  display: flex;
`;
export const MiddleLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const VennContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  height: 15%;
  align-items: center;
`;

export const TwoColContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  height: 15%;
  align-items: center;
`;
export const ThreeColContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 25%;
  justify-content: space-around;
  height: 100%;
  align-items: center;
`;

export const DiagramImageContainer = styled.div`
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ButtonText = styled.span`
  position: "absolute";
  top: "50%";
  left: "50%";
  transform: "translate(-50%, -50%)";
`;
