import React from "react";
import { RangeTabsContainer, RangeTab } from "./RangeSelector.modulecss";

interface RangeSelectorProps {
  selectedRangeName: string;
  onSelectRange: (rangeName: string) => void;
  maxRange: string;
}

export const RangeSelector = (props: RangeSelectorProps) => {
  return (
    <RangeTabsContainer>
      <RangeTab
        onClick={() => props.onSelectRange("DAY")}
        selected={props.selectedRangeName === "DAY"}
      >
        1 Day
      </RangeTab>
      <RangeTab
        onClick={() => props.onSelectRange("TWODAY")}
        selected={props.selectedRangeName === "TWODAY"}
      >
        2 Days
      </RangeTab>
      <RangeTab
        onClick={() => props.onSelectRange("HALFWEEK")}
        selected={props.selectedRangeName === "HALFWEEK"}
        disabled={["TWODAY"].includes(props.maxRange)}
      >
        4 Days
      </RangeTab>
      <RangeTab
        onClick={() => props.onSelectRange("WEEK")}
        selected={props.selectedRangeName === "WEEK"}
        disabled={["TWODAY", "HALFWEEK"].includes(props.maxRange)}
      >
        1 Week
      </RangeTab>
      <RangeTab
        onClick={() => props.onSelectRange("MONTH")}
        selected={props.selectedRangeName === "MONTH"}
        disabled={["TWODAY", "HALFWEEK", "WEEK"].includes(props.maxRange)}
      >
        4 Weeks
      </RangeTab>
      <RangeTab
        onClick={() => props.onSelectRange("QUARTERYEAR")}
        selected={props.selectedRangeName === "QUARTERYEAR"}
        disabled={["TWODAY", "HALFWEEK", "WEEK", "MONTH"].includes(
          props.maxRange
        )}
      >
        12 Weeks
      </RangeTab>
      <RangeTab
        onClick={() => props.onSelectRange("YEAR")}
        selected={props.selectedRangeName === "YEAR"}
        disabled={[
          "TWODAY",
          "HALFWEEK",
          "WEEK",
          "MONTH",
          "QUARTERYEAR"
        ].includes(props.maxRange)}
      >
        1 Year
      </RangeTab>
      <RangeTab
        onClick={() => props.onSelectRange("TWOYEAR")}
        selected={props.selectedRangeName === "TWOYEAR"}
        disabled={[
          "TWODAY",
          "HALFWEEK",
          "WEEK",
          "MONTH",
          "QUARTERYEAR",
          "YEAR"
        ].includes(props.maxRange)}
      >
        2 Years
      </RangeTab>
    </RangeTabsContainer>
  );
};
