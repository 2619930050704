import styled from "@emotion/styled";
import { Colors } from "../../css/styles.modulecss";

export const Header = styled.div`
  width: 100%;
  display: none;
  justify-content: space-between;
  background-color: ${Colors.black};
  padding: 10px 0;
  z-index: 9999;
  min-height: 30px;
  align-items: center;
`;

export const RightWrapper = styled.div`
  display: flex;
  color: ${Colors.black};
`;

export const MainWelcomeWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 60px 0;
  @media screen and (orientation: portrait) and (max-width: 767px) {
    height: auto;
  }
  @media screen and (orientation: landscape) and (max-width: 767px) {
    height: 100%;
  }
`;

export const MainContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 0 15px;
  margin: 0 auto;
`;

export const WelcomeWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (orientation: portrait) and (max-width: 767px) {
    height: auro;
  }
  @media screen and (orientation: landscape) and (max-width: 767px) {
    height: 100%;
  }
  /* Landscape */
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
    height: auto;
  }
`;

export const WelcomeBlock = styled.div`
  width: 50%;
  max-width: 750px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: ${Colors.white};
  padding: 50px 25px;
  color: ${Colors.black};
  text-align: center;
  border-radius: 20px;
  box-shadow: 5px 5px 12px 5px ${Colors.login_shadow};

  @media (max-width: 767px) {
    padding: 30px 10px;
  }
`;
