import styled from "@emotion/styled";
import { Colors, FontFamily, FontSize } from "../../../css/styles.modulecss";

interface ResultCardProps {
  isFilled: boolean;
}

export const ResultCard = styled.div<ResultCardProps>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => (props.isFilled ? Colors.white : Colors.grey)};
  width: 100%;
  height: 100%;
  padding: 30px 30px 15px 30px;
  position: relative;
  overflow-y: auto;
`;

export const ResultLists = styled.div`
  box-shadow: 1.5px 1.5px 6px 3px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-height: 400px;
  }
  div:hover {
    background-color: transparent;
  }
`;

export const ResultCardWrapper = styled.div`
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 10px 5px;
  border-bottom: 1px solid ${Colors.grey};
  cursor: pointer;
  border-radius: 0;
  :last-child {
    border-bottom: 0;
    border-radius: 15px;
  }
  :first-child {
    border-radius: 15px 15px 0 0;
  }
  :hover {
    background-color: ${Colors.grey};
  }
`;

export const ReadOnlySummary = styled.div`
  font-size: ${FontSize.smallText};
  padding-right: 10px;
`;

export const ReadOnlyHalfScreenSummary = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  font-size: ${FontSize.smallText};
  padding-top: 15px;
`;

export const ArticleDetailBody = styled.div`
  margin-top: 15px;
  flex: 1;
  display: flex;
  flex-direction: row;
  letter-spacing: 0px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const SearchIMG = styled.div`
  display: flex;
  max-width: 140px;
  margin-right: 5px;
  width: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-family: ${FontFamily.fontRoboto};
  letter-spacing: 0.5px;
  word-break: break-word;
  color: ${Colors.black};
  @media (max-width: 1199px) {
    font-size: ${FontSize.smallText};
    max-width: 70px;
  }
`;

export const SearchContent = styled.div`
  display: flex;
  width: calc(100% - 90px);
  flex-direction: column;
`;
export const ContentHead = styled.h2`
  font-size: ${FontSize.heading};
  line-height: 32px;
  color: ${Colors.black};
  font-weight: bold;
  margin: 0;
  margin-bottom: 5px;
  font-family: ${FontFamily.fontRoboto};
  letter-spacing: 0.3px;
  @media (max-width: 1199px) {
    font-size: 22px;
    line-height: 28px;
  }
  @media (max-width: 599px) {
    font-size: ${FontSize.largeText};
    line-height: 23px;
  }
`;

export const DateTime = styled.p`
  font-size: ${FontSize.smallText};
  color: ${Colors.black};
  font-weight: normal;
  line-height: normal;
  margin: 0;
  margin-bottom: 10px;
  font-family: ${FontFamily.fontRoboto};
  letter-spacing: 0.2px;
  @media (max-width: 1199px) {
    ${FontSize.smallText};
  }
  @media (max-width: 599px) {
    font-size: 14px;
  }
`;

export const DateReaction = styled.p`
  line-height: normal;
`;

export const News = styled.p`
  font-size: ${FontSize.smallText};
  line-height: 21px;
  color: ${Colors.black};
  font-weight: normal;
  line-height: normal;
  margin: 0;
  font-family: ${FontFamily.fontRoboto};
  letter-spacing: 0.2px;
  @media (max-width: 1199px) {
    font-size: ${FontSize.smallText};
  }
  @media (max-width: 599px) {
    font-size: 14px;
  }
  font-family: ${FontFamily.fontSans};
`;

export const ArticleContent = styled.div`
  flex: 0.6;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArticleInputs = styled.div`
  flex: 0.4;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 15px;
`;

export const ArticleInputsHalfScreen = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const HalfScreenRightContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ReadArticleButton = styled.button`
  justify-content: center;
  padding: 25px;
  width: 100%;
  background-color: #fff35b;
  border: 3.5px solid #fff35b;
  border-radius: 10px;
  :hover {
    background-color: white;
    border: 3.5px solid #ffdb24;
  }
`;

export const ReadArticleText = styled.div`
  color: ${Colors.black};
  font-family: ${FontFamily.fontRoboto};
  font-size: ${FontSize.largeText};
  font-weight: 600;
  text-align: center;
`;

export const SummaryContainer = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  border-bottom: 2px solid ${Colors.dark_grey};
  border-right: 2px solid black;
`;

export const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  height: 25px;
  width: 25px;
  font-size: 1.5rem;
  background-color: ${Colors.black};
  color: ${Colors.white};
  border: 1px solid ${Colors.black};
  font-family: ${FontFamily.fontRoboto};
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

export const LoadingContainer = styled.div`
  padding-top: 25px;
`;

export const TagAndReactionContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const EmoticonContainer = styled.div`
  padding: 10px 27px 10px 27px;
  margin: 5px 2.5px 0px 0px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background-color: #d3b2e3;
  border: 2.5px solid #d3b2e3;
  border-radius: 10px;
  :hover {
    background-color: white;
    border: 2.5px solid #af71d1;
  }
`;

export const EmoticonText = styled.text`
  color: ${Colors.black};
  font-size: ${FontSize.smallText};
  font-family: ${FontFamily.fontRoboto};
  font-weight: 600;
`;
