import React from "react";

import { LogoText, LogoWrapper } from "./Logo.module";

export const Logo = () => {
  return (
    <LogoWrapper>
      <LogoText href="https://app.mucktracker.com" target="_blank">
        <strong>Muck</strong>tracker
      </LogoText>
    </LogoWrapper>
  );
};
