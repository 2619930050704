import React from "react";
import ABC from "./Logos/abc.png";
import AP from "./Logos/associated-press.png";
import AJC from "./Logos/atlanta-journal-constitution.png";
import AZRepublic from "./Logos/arizonarepublic.jpg";
import BaltimoreSun from "./Logos/baltimore-sun.png";
import BBC from "./Logos/bbc.png";
import BostonGlobe from "./Logos/boston-globe.png";
import BuzzFeed from "./Logos/buzzfeed.png";
import CBS from "./Logos/CBS_Eyemark.png";
import CharlotteObserver from "./Logos/charlotteobserver.png";
import ChicagoTribune from "./Logos/Chicago-tribune.png";
import Cleveland from "./Logos/cleveland.png";
import CNN from "./Logos/cnn.png";
import CNBC from "./Logos/cnbc-logo-transparent.png";
import DallasNews from "./Logos/dallasnews.png";
import DenverPost from "./Logos/denverpost.png";
import ForwardTimes from "./Logos/forwardtimes.jpg";
import Fox from "./Logos/fox-news.png";
import HonoluluStar from "./Logos/Honolulu-star-advertiser.png";
import Inquirer from "./Logos/inquirer.jpg";
import LATimes from "./Logos/los-angeles-times.png";
import MinneapolisStar from "./Logos/minneapolis-star-tribune.png";
import MDJOnline from "./Logos/mdjonline.jpg";
import MercuryNews from "./Logos/mercurynews.jpg";
import NBC from "./Logos/nbc.png";
import NewarkStar from "./Logos/njcom.png";
import Newsday from "./Logos/newsday.jpg";
import NewsObserver from "./Logos/newsobserver.jpg";
import NYPost from "./Logos/new-york-post.png";
import NPR from "./Logos/npr.png";
import NYTimes from "./Logos/nytimesico.png";
import PortlandOregonian from "./Logos/portland-oregonian.png";
import PostGazette from "./Logos/post-gazette.jpg";
import Reuters from "./Logos/reuters.jpeg";
import SacBee from "./Logos/sacbee.png";
import SeattleTimes from "./Logos/seattle-times.png";
import SLTrib from "./Logos/sltrib.jpg";
import StLouisPost from "./Logos/st-louis-post-dispatch.jpg";
import HoustonChron from "./Logos/houston.png";
import SfChronicle from "./Logos/sf-chronicle-logo.jpg";
import TampaBayTimes from "./Logos/tampa-bay-times.png";
import UnionLeader from "./Logos/unionleader.png";
import USAToday from "./Logos/USA-Today.png";
import USNews from "./Logos/US-News.png";
import WallStreetJournal from "./Logos/wall-street-journal.png";
import WashingtonPost from "./Logos/Washington-Post-png.png";

import { SearchIMG, SourceText } from "./Logos.modulecss";
import { SOURCES } from "utility/sources-config";
interface LogosProps {
  source_name: string;
  provider_display_name: string;
}
export const Logos = (props: LogosProps) => {
  switch (props.source_name) {
    case SOURCES.abc.external_id:
      return <SearchIMG src={ABC} alt="ABC News Logo" />;
    case SOURCES.ap.external_id:
      return <SearchIMG src={AP} alt="The Associated Press Logo" />;
    case SOURCES.ajc.external_id:
      return (
        <SearchIMG src={AJC} alt="The Atlanta Journal-Constitution Logo" />
      );
    case SOURCES.azcentral.external_id:
      return <SearchIMG src={AZRepublic} alt="The Arizona Republic Logo" />;
    case SOURCES.baltimoresun.external_id:
      return <SearchIMG src={BaltimoreSun} alt="The Baltimore Sun Logo" />;
    case SOURCES.bbc.external_id:
      return <SearchIMG src={BBC} alt="The BBC Logo" />;
    case SOURCES.bostonglobe.external_id:
      return <SearchIMG src={BostonGlobe} alt="The Boston Globe Logo" />;
    case SOURCES.buzzfeednews.external_id:
      return <SearchIMG src={BuzzFeed} alt="BuzzFeed's Logo" />;
    case SOURCES.cbsnews.external_id:
      return <SearchIMG src={CBS} alt="CBS News Logo" />;
    case SOURCES.charlotteobserver.external_id:
      return (
        <SearchIMG src={CharlotteObserver} alt="The Charlotte Observer Logo" />
      );
    case SOURCES.chron.external_id:
      return <SearchIMG src={HoustonChron} alt="The Houston Chronicle Logo" />;
    case SOURCES.chicagotribune.external_id:
      return <SearchIMG src={ChicagoTribune} alt="The Chicago Tribune Logo" />;
    case SOURCES.cleveland.external_id:
      return <SearchIMG src={Cleveland} alt="Cleveland Logo" />;
    case SOURCES.cnn.external_id:
      return <SearchIMG src={CNN} alt="CNN Logo" />;
    case SOURCES.cnbc.external_id:
      return <SearchIMG src={CNBC} alt="CNBC Logo" />;
    case SOURCES.dallasnews.external_id:
      return <SearchIMG src={DallasNews} alt="Dallas News Logo" />;
    case SOURCES.denverpost.external_id:
      return <SearchIMG src={DenverPost} alt="The Denver Post Logo" />;
    case SOURCES.forwardtimes.external_id:
      return <SearchIMG src={ForwardTimes} alt="Houston Forward Times Logo" />;
    case SOURCES.foxnews.external_id:
      return <SearchIMG src={Fox} alt="Fox Logo" />;
    case SOURCES.inquirer.external_id:
      return <SearchIMG src={Inquirer} alt="The Inquirer Logo" />;
    case SOURCES.latimes.external_id:
      return <SearchIMG src={LATimes} alt="The LA Times Logo" />;
    case SOURCES.startribune.external_id:
      return (
        <SearchIMG src={MinneapolisStar} alt="The Mineeapolis Star Logo" />
      );
    case SOURCES.mdjonline.external_id:
      return <SearchIMG src={MDJOnline} alt="Marietta Daily Journal Logo" />;
    case SOURCES.mercurynews.external_id:
      return <SearchIMG src={MercuryNews} alt="Mercury News Logo" />;
    case SOURCES.nbcnews.external_id:
      return <SearchIMG src={NBC} alt="NBC Logo" />;
    case SOURCES.newsday.external_id:
      return <SearchIMG src={Newsday} alt="Newsday Logo" />;
    case SOURCES.newsobserver.external_id:
      return <SearchIMG src={NewsObserver} alt="The News and Observer Logo" />;
    case SOURCES.nj.external_id:
      return <SearchIMG src={NewarkStar} alt="Newark Star Logo" />;
    case SOURCES.nypost.external_id:
      return <SearchIMG src={NYPost} alt="The New York Post Logo" />;
    case SOURCES.npr.external_id:
      return <SearchIMG src={NPR} alt="National Public Radio Logo" />;
    case SOURCES.nytimes.external_id:
      return <SearchIMG src={NYTimes} alt="The New York Times Logo" />;
    case SOURCES.oregonlive.external_id:
      return <SearchIMG src={PortlandOregonian} alt="The Oregonian Logo" />;
    case SOURCES["post-gazette"].external_id:
      return <SearchIMG src={PostGazette} alt="The Post-Gazette Logo" />;
    case SOURCES.reuters.external_id:
      return <SearchIMG src={Reuters} alt="The Reuters Logo" />;
    case SOURCES.sacbee.external_id:
      return <SearchIMG src={SacBee} alt="The Sacramento Bee Logo" />;
    case SOURCES.seattletimes.external_id:
      return <SearchIMG src={SeattleTimes} alt="The Seattle Times Logo" />;
    case SOURCES.sfchronicle.external_id:
      return <SearchIMG src={SfChronicle} alt="San Francisco Chronicle Logo" />;
    case SOURCES.sltrib.external_id:
      return <SearchIMG src={SLTrib} alt="The Salt Lake Tribune Logo" />;
    case SOURCES.staradvertiser.external_id:
      return (
        <SearchIMG src={HonoluluStar} alt="Honolulu Star-Advertiser Logo" />
      );
    case SOURCES.stlouispost.external_id:
      return <SearchIMG src={StLouisPost} alt="St. Louis Post-Dispatch Logo" />;
    case SOURCES.tampabay.external_id:
      return <SearchIMG src={TampaBayTimes} alt="Tampa Bay Times Logo" />;
    case SOURCES.unionleader.external_id:
      return (
        <SearchIMG src={UnionLeader} alt="New Hampshire Union Leader Logo" />
      );
    case SOURCES.usatoday.external_id:
      return <SearchIMG src={USAToday} alt="USA Today Logo" />;
    case SOURCES.usnews.external_id:
      return <SearchIMG src={USNews} alt="US News Logo" />;
    case SOURCES.wsj.external_id:
      return (
        <SearchIMG src={WallStreetJournal} alt="Wall Street Journal Logo" />
      );
    case SOURCES.washingtonpost.external_id:
      return <SearchIMG src={WashingtonPost} alt="The Washington Post Logo" />;
    default:
      return <SourceText>{props.provider_display_name}</SourceText>;
  }
};
