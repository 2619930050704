import React, { createContext } from "react";

type UserStateContextDefinition = {
  id: number | null;
  username: string | null;
  register_as: string | null;
  first_name: string | null;
  last_name: string | null;
  is_staff: boolean;
};
const DEFAULT_STATE = {
  id: null,
  username: null,
  register_as: null,
  first_name: null,
  last_name: null,
  is_staff: false
};

export const LOGIN_ACTION = "login";
export const LOGOUT_ACTION = "logout";
type UserActionType =
  | { type: typeof LOGIN_ACTION }
  | { type: typeof LOGOUT_ACTION };
type UserAction = UserActionType & { data?: any };
export type UserDispatch = (action: UserAction) => void;

export const UserStateContext =
  createContext<UserStateContextDefinition>(DEFAULT_STATE);
const AppReducer = (state, action) => {
  switch (action.type) {
    case LOGIN_ACTION: {
      return {
        ...state,
        id: action.data.id,
        username: action.data.username,
        register_as: action.data.register_as,
        first_name: action.data.first_name,
        last_name: action.data.last_name,
        is_staff: action.data.is_staff
      };
    }
    case LOGOUT_ACTION: {
      return { ...DEFAULT_STATE };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};
export const UserDispatchContext = createContext<UserDispatch>(() => {
  console.error("Context not initialized");
});

export const UserContextProvider = (props: { children: any }) => {
  const [user, dispatch] = React.useReducer(AppReducer, DEFAULT_STATE);

  return (
    <UserStateContext.Provider value={user}>
      <UserDispatchContext.Provider value={dispatch}>
        {props.children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};
