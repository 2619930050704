import moment, { Moment } from "moment";
import { ArticleData } from "services/article-service";

export interface BucketViz {
  height: number;
  position: number;
  widthPct: number;
  prevPosition?: number;
  prevHeight?: number;
  prevWidthPct?: number;
  articles: ArticleData[];
  hasFirstArticle?: boolean;
}

interface RangeConfig {
  numBuckets: number;
  bucketRange: number;
  rangeUnit: RANGE_UNIT;
  rangeName: string;
}

export interface FlagConfig {
  flagPosition: number;
  flagArticles: ArticleData[];
  flagHeight: number;
  flagWidthPct: number;
  totalArticles: number;
}

type RANGE_CONFIG_MAP_TYPE = {
  [key: string]: RangeConfig;
};
// The values here correspond to Moment strings
export enum RANGE_UNIT {
  HOURS = "hours",
  DAYS = "days",
  MONTHS = "months"
}
export const RANGE_CONFIGS: RANGE_CONFIG_MAP_TYPE = {
  //zoom level
  TWOYEAR: {
    numBuckets: 12,
    bucketRange: 2,
    rangeUnit: RANGE_UNIT.MONTHS,
    rangeName: "TWOYEAR"
  },
  YEAR: {
    numBuckets: 12,
    bucketRange: 1,
    rangeUnit: RANGE_UNIT.MONTHS,
    rangeName: "YEAR"
  },
  QUARTERYEAR: {
    numBuckets: 12,
    bucketRange: 7,
    rangeUnit: RANGE_UNIT.DAYS,
    rangeName: "QUARTERYEAR" // 84 days, checks out.
  },
  MONTH: {
    numBuckets: 8,
    bucketRange: 4,
    rangeUnit: RANGE_UNIT.DAYS,
    rangeName: "MONTH" //32 days checks out
  },
  WEEK: {
    numBuckets: 7,
    bucketRange: 1,
    rangeUnit: RANGE_UNIT.DAYS,
    rangeName: "WEEK" //7 days, checks out.
  },
  HALFWEEK: {
    numBuckets: 8,
    bucketRange: 12,
    rangeUnit: RANGE_UNIT.HOURS,
    rangeName: "HALFWEEK" //4 days, checks out
  },
  TWODAY: {
    numBuckets: 8,
    bucketRange: 6,
    rangeUnit: RANGE_UNIT.HOURS,
    rangeName: "TWODAY" //Two Days
  },
  DAY: {
    numBuckets: 12,
    bucketRange: 2,
    rangeUnit: RANGE_UNIT.HOURS,
    rangeName: "DAY" //One Day
  }
};

const _getEmptyBucketViz = (numBuckets: number) => {
  const result: BucketViz[] = [];
  for (let i = 0; i < numBuckets; i++) {
    result.push({
      height: 0,
      position: 0,
      widthPct: 0,
      articles: []
    });
  }
  return result;
};

export const getStartForRange = (rangeConfig: RangeConfig) => {
  // Start calculation on a round range unit
  const startOfLastBucket = moment().local().startOf(rangeConfig.rangeUnit);
  // Slide to start of bucket
  if (rangeConfig.rangeUnit === RANGE_UNIT.HOURS) {
    // For hours, start at a multiple of the range from the start of the day
    startOfLastBucket.subtract(
      startOfLastBucket.hour() % rangeConfig.bucketRange,
      "hours"
    );
  } else if (rangeConfig.rangeUnit === RANGE_UNIT.DAYS) {
    // For days, today should be the last day in the bucket
    startOfLastBucket.subtract(rangeConfig.bucketRange - 1, "days");
  } else {
    // For months, start at a multiple of the range from the start of the year
    startOfLastBucket.subtract(
      startOfLastBucket.month() % rangeConfig.bucketRange,
      "months"
    );
  }

  const startOfFirstBucket = startOfLastBucket
    .clone()
    .subtract(
      (rangeConfig.numBuckets - 1) * rangeConfig.bucketRange,
      rangeConfig.rangeUnit
    );
  return startOfFirstBucket;
};

export const getEarliestBucketStart = (
  articles: ArticleData[],
  rangeConfig: RangeConfig
) => {
  const articleRangeStart = moment(articles[articles.length - 1].published_at)
    .utc(true)
    .local(); //oldest article
  const endOfLastBucket = moment().local().endOf(rangeConfig.rangeUnit);
  const earliestBucketStart = endOfLastBucket.clone();

  while (earliestBucketStart.diff(articleRangeStart) > 0) {
    earliestBucketStart.subtract(
      rangeConfig.bucketRange * rangeConfig.numBuckets,
      rangeConfig.rangeUnit
    );
  }

  return earliestBucketStart;
};

export const getTotalTimeRange = (
  earliestBucketStart: Moment,
  rangeConfig: RangeConfig
) => {
  const endOfLastBucket = moment().local().endOf(rangeConfig.rangeUnit);
  return endOfLastBucket.diff(earliestBucketStart, rangeConfig.rangeUnit);
};

export const generateBucketViz = (
  articles: ArticleData[],
  config: RangeConfig,
  startDate: moment.Moment,
  previousConfig?: RangeConfig,
  previousStartDate?: moment.Moment
): BucketViz[] => {
  const bucketViz = _getEmptyBucketViz(config.numBuckets);
  articles.map((article: ArticleData, index: number) => {
    const { published_at } = article;
    const bucketIndex = Math.floor(
      moment(published_at).utc(true).local().diff(startDate, config.rangeUnit) /
        config.bucketRange
    );
    if (bucketIndex < config.numBuckets && bucketIndex >= 0) {
      bucketViz[bucketIndex].height += 1;
      bucketViz[bucketIndex].articles.push(article);
      // For the "current" vizualization, the position of each bucket should (trivially) be the index
      bucketViz[bucketIndex].position = bucketIndex;
      // Articles are sorted by date with the most recent first
      bucketViz[bucketIndex].hasFirstArticle = index === articles.length - 1;
      if (previousConfig && previousStartDate) {
        const publishedAtDate = moment(published_at).utc(true).local();
        const previousBucketIndex = Math.floor(
          publishedAtDate.diff(previousStartDate, previousConfig.rangeUnit) /
            previousConfig.bucketRange
        );
        //less than range set to left edge.
        //greater than buckets in new config set to right edge.
        if (previousBucketIndex < 0) {
          bucketViz[bucketIndex].prevPosition = 0;
        } else if (previousBucketIndex >= config.numBuckets) {
          bucketViz[bucketIndex].prevPosition = config.numBuckets - 1;
        } else {
          bucketViz[bucketIndex].prevPosition = previousBucketIndex;
        }
        bucketViz[bucketIndex].prevWidthPct = 100 / previousConfig.numBuckets;
      }
    }
  });
  for (const i in bucketViz) {
    bucketViz[i].widthPct = 100 / config.numBuckets;
    if (previousConfig) {
      bucketViz[i].prevWidthPct = 100 / previousConfig.numBuckets;
    }
  }
  return bucketViz;
};

export const getLabels = (config: RangeConfig, startDate: moment.Moment) => {
  const labels: string[] = [];
  let labelDate = startDate.clone();
  for (let i = 0; i <= config.numBuckets; i++) {
    const newDate = labelDate.clone();
    if (config.rangeName === "TWOYEAR") {
      const DECEMBER_MOMENT_NUM = 11;
      newDate.month() === DECEMBER_MOMENT_NUM
        ? labels.push(
            newDate.clone().format("MMM YYYY").toString() +
              " -" +
              newDate.clone().add(1, "month").format("[\n]MMM YYYY").toString()
          )
        : labels.push(
            newDate.clone().format("MMM").toString() +
              " - " +
              newDate.clone().add(1, "month").format("MMM[\n]YYYY").toString()
          );
    } else if (config.rangeName === "YEAR") {
      labels.push(newDate.clone().format("MMM YYYY").toString());
    } else if (config.rangeName === "WEEK") {
      labels.push(newDate.clone().format("MMM DD[\n]YYYY").toString());
    } else if (config.rangeName === "HALFWEEK") {
      labels.push(newDate.clone().format("MMM DD [(]A[)][\n]YYYY").toString());
    } else if (config.rangeName === "TWODAY" || config.rangeName === "DAY") {
      labels.push(
        newDate.clone().format("hA").toString() +
          "–" +
          newDate
            .clone()
            .add(config.bucketRange, "hours")
            .format("hA[\n]MMM DD[\n]YYYY")
      );
    } else {
      labels.push(
        newDate.clone().format("M.DD").toString() +
          "–" +
          newDate
            .clone()
            .add(config.bucketRange - 1, "days")
            .format("M.DD[\n]YYYY")
            .toString()
      );
    }
    labelDate = newDate
      .clone()
      .local()
      .add(config.bucketRange, config.rangeUnit);
  }
  return labels;
};

export const getFlagConfig = (
  nonEmptyBuckets: BucketViz[],
  rangeConfig: RangeConfig
) => {
  let lastBucketPosition = -2;
  const flagConfig: FlagConfig[] = [];
  for (let i = 0; i < nonEmptyBuckets.length; i++) {
    const bucketViz = nonEmptyBuckets[i];
    // Only create a flag for every other bucket
    if (lastBucketPosition === bucketViz.position - 1) {
      continue;
    }

    // No flag on the right-most bucket
    if (bucketViz.position === rangeConfig.numBuckets - 1) {
      continue;
    }

    const flagPosition = (100 / rangeConfig.numBuckets) * bucketViz.position;
    flagConfig.push({
      flagPosition,
      flagArticles: bucketViz.articles,
      totalArticles: bucketViz.articles.length,
      flagWidthPct: bucketViz.widthPct * 2 - 1,
      flagHeight: (bucketViz.articles.length % 5) / 5
    });

    lastBucketPosition = bucketViz.position;
  }
  return flagConfig;
};

export const zoomLabel = (range: string | null) => {
  let zoomLevel;
  if (range === "TWOYEAR") {
    zoomLevel = "Two Year (2 month increments)";
  } else if (range === "YEAR") {
    zoomLevel = "Year (Month increments)";
  } else if (range === "QUARTERYEAR") {
    zoomLevel = "Quarter Year (7 day increments)";
  } else if (range === "MONTH") {
    zoomLevel = "Month (4 day increments)";
  } else if (range === "WEEK") {
    zoomLevel = "Week (1 day increments)";
  } else if (range === "HALFWEEK") {
    zoomLevel = "Half-week (12 hour increments)";
  } else if (range === "TWODAY") {
    zoomLevel = "Two Day (6 hour increments)";
  } else if (range === "DAY") {
    zoomLevel = "Day (2 hour increments)";
  }
  return zoomLevel;
};
