import React from "react";
import LogoSrc from "./assets/logo.png";

export const ColorLogo = () => {
  return (
    <img
      alt="Mucktracker"
      style={{ height: 150, width: 500 }}
      src={LogoSrc}
    ></img>
  );
};

export const ColorLogoTitle = () => {
  return (
    <img
      alt="Mucktracker"
      style={{ width: "90%", height: "auto" }}
      src={LogoSrc}
    ></img>
  );
};
