import React from "react";
import { CommonHtmlElement } from "../../../css/styles.modulecss";
import { SummarizeBlock, ModalTextArea, ButtonList } from "./Summary.modulecss";
import { debounce } from "debounce";

interface SummaryProps {
  savedSummary: string;
  setSummary: (summary: string) => Promise<void>;
  errorMessage: string | null;
}
export default function Summary(props: SummaryProps) {
  const [summaryInputText, setSummaryInputText] = React.useState<string>(
    props.savedSummary
  );

  const update = (updateText: string) => {
    props.setSummary(updateText);
  };
  const debouncedUpdate = React.useCallback(debounce(update, 3000), [
    props.savedSummary
  ]);

  const handleSummaryChange = (e) => {
    setSummaryInputText(e.target.value);
    debouncedUpdate(e.target.value);
  };

  return (
    <SummarizeBlock>
      <ModalTextArea
        placeholder="Add notes about this article..."
        rows={8}
        value={summaryInputText}
        onChange={(e) => handleSummaryChange(e)}
        aria-label="User generated article notes"
      />
      <ButtonList>
        {props.errorMessage ? (
          <CommonHtmlElement.HasError>
            {props.errorMessage}
          </CommonHtmlElement.HasError>
        ) : null}
      </ButtonList>
    </SummarizeBlock>
  );
}
