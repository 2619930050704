import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { makeStyles } from "mui-styles";
import { Colors, FontFamily, FontSize } from "../../../css/styles.modulecss";

export const SearchBoxWrapper = styled.div`
  font-size: ${FontSize.heading};
  box-shadow: 1px 1px 10px ${Colors.light_purple};
  border-radius: 10px;
  margin: 10px 0;
`;

export const searchBarStyles = makeStyles((theme: Theme) => ({
  searchbar: {
    "&.MuiFormControl-root": {
      margin: theme.spacing(1),
      width: "95%",
      "& .MuiInput-underline:before": {
        border: "0"
      },
      "& .MuiInput-underline:after": {
        border: "0"
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        border: "0"
      },
      "& .MuiSvgIcon-root": {
        fill: Colors.light_grey
      },
      "@media(max-width:599px)": {
        // TODO: Do not render this component rather than an inaccessible display none
        display: "none"
      },
      "& .MuiInputBase-input": {
        fontSize: "16px",
        fontFamily: FontFamily.fontSans
      }
    }
  },
  searchbarMobile: {
    "&.MuiFormControl-root": {
      "@media(max-width:599px)": {
        display: "block",
        width: "100%",
        padding: "3px 3px",
        margin: "0",
        marginTop: "5px"
      },
      margin: theme.spacing(1),
      "& .MuiInput-underline:before": {
        border: "0"
      },
      "& .MuiInput-underline:after": {
        border: "0"
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        border: "0"
      },
      "& .MuiSvgIcon-root": {
        fill: Colors.light_grey
      },
      // TODO: Do not render this component rather than an inaccessible display none
      display: "none"
    }
  }
}));
