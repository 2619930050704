import React from "react";
import styled from "@emotion/styled";
import { Bounce } from "react-activity";
import { Colors } from "css/styles.modulecss";

// TODO: finish refactoring styles for this file
interface LoadingOrErrorProps {
  style?: any;
  loadingMsg?: string;
  errMsg?: string;
}

function LoadingOrError({ loadingMsg, errMsg, style }: LoadingOrErrorProps) {
  if (loadingMsg) {
    return (
      <Container>
        <Title style={style}>{loadingMsg}</Title>
        <Bounce />
      </Container>
    );
  } else if (errMsg) {
    return (
      <Container>
        <Error>Error: {errMsg}</Error>
      </Container>
    );
  } else {
    return null;
  }
}

export default LoadingOrError;

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Title = styled.div`
  margin-right: 20px;
  font-size: 18px;
  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;
const Error = styled.div`
  font-size: 18px;
  color: ${Colors.red};
  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;
