import {
  ClearCanvasModalContainer,
  ClearCanvasModalLayout,
  LayoutButtonContainer,
  ClearCanvasButton,
  ClearCanvasHeader
} from "./ClearCanvasModal.modulecss";
import React from "react";

interface ClearCanvasModalProps {
  handleClearCanvas: () => void;
  handleCloseModal: () => void;
}

export const ClearCanvasModal = (props: ClearCanvasModalProps) => {
  const handleClearCanvas = () => {
    props.handleClearCanvas();
    props.handleCloseModal();
  };

  return (
    <ClearCanvasModalLayout>
      <ClearCanvasModalContainer>
        <ClearCanvasHeader>
          Clear canvas of all articles and labels?
        </ClearCanvasHeader>
        <LayoutButtonContainer>
          <ClearCanvasButton onClick={handleClearCanvas}>
            Confirm
          </ClearCanvasButton>
          <ClearCanvasButton onClick={props.handleCloseModal}>
            Cancel
          </ClearCanvasButton>
        </LayoutButtonContainer>
      </ClearCanvasModalContainer>
    </ClearCanvasModalLayout>
  );
};
