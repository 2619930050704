import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Colors, FontSize } from "../../../css/styles.modulecss";
import { makeStyles } from "mui-styles";

export const loginFormStyles = makeStyles(() => ({
  multilineColor: {
    color: Colors.black
  },
  textField: {
    color: Colors.black,
    position: "relative"
  },
  label: {
    "&$erroredLabel": {
      color: Colors.red
    },
    "&$focusedLabel": {
      color: Colors.black
    },
    color: Colors.black,
    fontSize: FontSize.smallText
  },
  focusedLabel: {},
  erroredLabel: {}
}));

export const loginHelperTextStyles = makeStyles(() => ({
  root: {
    "&$error": {
      position: "absolute",
      top: "100%",
      left: "0"
    }
  },
  error: {}
}));

const FormWrapper = styled.div`
  max-width: 430px;
  margin: 0 auto;
  width: 100%;
`;
const CheckSpan = styled.span`
  font-size: ${FontSize.smallText};
`;
const CheckboxRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;
const ForgotLink = styled.a`
  display: column;
  color: ${Colors.bright_blue};
  font-weight: 600;
  text-decoration: none;
  margin-top: 9px;
  font-size: ${FontSize.smallText};
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  button {
    background-color: ${Colors.yellow};
    border: 1px solid ${Colors.yellow};
    color: ${Colors.black};
    text-decoration: none;
    width: 48%;
    text-transform: none;
    font-size: ${FontSize.largeText};
    :hover {
      background-color: transparent;
      color: ${Colors.black};
      border: 1px solid ${Colors.white};
    }
    @media (max-width: 767px) {
      font-size: ${FontSize.smallText};
    }
  }
  a {
    text-decoration: none;
    width: 48%;
    margin-left: 4%;
    button {
      width: 100%;
    }
  }
`;
const HeadingWrapper = styled.div``;

const SignupLink = styled(Link)`
  color: ${Colors.bright_blue};
  font-weight: 600;
  text-decoration: none;
  font-size: ${FontSize.smallText};
  :hover {
    color: ${Colors.yellow_hover};
  }
`;
const ForgotText = styled.div`
  margin-top: 10px;
  color: ${Colors.dark_grey};
  font-size: ${FontSize.smallText};
`;
const MainContent = styled.p`
  color: ${Colors.dark_grey};
  max-width: 550px;
  margin: 0 auto;
  font-size: ${FontSize.largeText};
  line-height: normal;
  margin-top: 15px;
  margin-bottom: 25px;
`;

export {
  FormWrapper,
  CheckSpan,
  CheckboxRow,
  ForgotLink,
  SignupLink,
  ButtonRow,
  HeadingWrapper,
  MainContent,
  ForgotText
};
