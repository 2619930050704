import styled from "@emotion/styled";
import { Colors, FontFamily, FontSize } from "../../../css/styles.modulecss";
import { makeStyles } from "mui-styles";

export const modalContentStyles = makeStyles(() => ({
  modalPlacement: {
    width: "100vw",
    height: "50vh",
    marginLeft: "25%",
    marginTop: "8%"
  },
  dialogPaper: {
    minHeight: "200px",
    maxHeight: "200px",
    borderRadius: "15px"
  },
  customWidth: {
    maxWidth: 719,
    backgroundColor: `${Colors.white}`,
    fontSize: "11px",
    color: `${Colors.slate_gray}`,
    fontWeight: 500,
    boxShadow: "0px 2px 10px #00000027",
    padding: "8px 20px",
    textAlign: "center"
  },
  arrowStyles: {
    color: `${Colors.white}`,
    boxShadow: "#00000027"
  }
}));

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.5px;
  font-family: ${FontFamily.fontRoboto};
  letter-spacing: 0px;
  height: auto;
  width: 20%;
`;

export const TagContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  margin: 5px;
  font-family: ${FontFamily.fontRoboto};
  border: 3px solid #c7c7d1;
  border-radius: 10px;
  height: 34px;
`;

export const DialogTagContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  margin: 5px;
  width: 38%;
  font-family: ${FontFamily.fontRoboto};
  border: 1px solid ${Colors.black};
  border-radius: 15px;
  height: 28px;
`;
export const TagText = styled.div`
  display: flex;
  width: 70%;
  padding: 2px;
  margin: 5px;
  font-family: ${FontFamily.fontRoboto};
  font-size: ${FontSize.smallText};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  height: 20px;
  width: 20px;
  background-color: ${Colors.black};
  color: ${Colors.white};
  border: 1px solid ${Colors.black};
  font-family: ${FontFamily.fontRoboto};
  border-radius: 50%;
  cursor: pointer;
`;
export const ExpandTagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
`;
export const ExpandTags = styled.a`
  margin-right: 5px;
  font-family: ${FontFamily.fontRoboto};
  color: ${Colors.blue};
  max-width: 50%;
  font-size: ${FontSize.smallText};
`;

export const AllTags = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

export const ButtonText = styled.span`
  position: "absolute";
  top: "50%";
  left: "50%";
  transform: "translate(-50%, -50%)";
`;
