export const SOURCES: {
  [key: string]: { external_id: string; display_name: string };
} = {
  abc: {
    external_id: "abc",
    display_name: "ABC"
  },
  ap: {
    external_id: "ap",
    display_name: "Associated Press"
  },
  ajc: {
    external_id: "ajc",
    display_name: "Atlanta Journal Constitution"
  },
  azcentral: {
    external_id: "azcentral",
    display_name: "Arizona Republic"
  },
  baltimoresun: {
    external_id: "baltimoresun",
    display_name: "Baltimore Sun"
  },
  bbc: {
    external_id: "bbc",
    display_name: "BBC"
  },
  bostonglobe: {
    external_id: "bostonglobe",
    display_name: "Boston Globe"
  },
  buzzfeednews: {
    external_id: "buzzfeednews",
    display_name: "Buzzfeed"
  },
  cbsnews: {
    external_id: "cbsnews",
    display_name: "CBS"
  },
  charlotteobserver: {
    external_id: "charlotteobserver",
    display_name: "Charlotte Observer"
  },
  chron: {
    external_id: "chron",
    display_name: "Houston Chronicle"
  },
  chicagotribune: {
    external_id: "chicagotribune",
    display_name: "Chicago Tribune"
  },
  cleveland: {
    external_id: "cleveland",
    display_name: "Cleveland Plain Dealer"
  },
  cnn: {
    external_id: "cnn",
    display_name: "CNN"
  },
  cnbc: {
    external_id: "cnbc",
    display_name: "CNBC"
  },
  dallasnews: {
    external_id: "dallasnews",
    display_name: "Dallas Morning News"
  },
  denverpost: {
    external_id: "denverpost",
    display_name: "Denver Post"
  },
  forwardtimes: {
    external_id: "forwardtimes",
    display_name: "Houston Forward Times"
  },
  foxnews: {
    external_id: "foxnews",
    display_name: "Fox"
  },
  inquirer: {
    external_id: "inquirer",
    display_name: "Philadelphia Inquirer"
  },
  latimes: {
    external_id: "latimes",
    display_name: "Los Angeles Times"
  },
  startribune: {
    external_id: "startribune",
    display_name: "Minneapolis Star Tribune"
  },
  mdjonline: {
    external_id: "mdjonline",
    display_name: "Marietta Daily Journal"
  },
  mercurynews: {
    external_id: "mercurynews",
    display_name: "Mercury News"
  },
  nbcnews: {
    external_id: "nbcnews",
    display_name: "NBC"
  },
  newsday: {
    external_id: "newsday",
    display_name: "Newsday"
  },
  newsobserver: {
    external_id: "newsobserver",
    display_name: "The News & Observer"
  },
  nj: {
    external_id: "nj",
    display_name: "NJ.com"
  },
  nypost: {
    external_id: "nypost",
    display_name: "New York Post"
  },
  npr: {
    external_id: "npr",
    display_name: "NPR"
  },
  nytimes: {
    external_id: "nytimes",
    display_name: "New York Times"
  },
  oregonlive: {
    external_id: "oregonlive",
    display_name: "Portland Oregonian"
  },
  "post-gazette": {
    external_id: "post-gazette",
    display_name: "Pittsburgh Post-Gazette"
  },
  reuters: {
    external_id: "reuters",
    display_name: "Reuters"
  },
  sacbee: {
    external_id: "sacbee",
    display_name: "Sacramento Bee"
  },
  seattletimes: {
    external_id: "seattletimes",
    display_name: "Seattle Times"
  },
  sfchronicle: {
    external_id: "sfchronicle",
    display_name: "San Francisco Chronicle"
  },
  sltrib: {
    external_id: "sltrib",
    display_name: "Salt Lake Tribune"
  },
  staradvertiser: {
    external_id: "staradvertiser",
    display_name: "Honolulu Star"
  },
  stlouispost: {
    external_id: "stltoday",
    display_name: "St. Louis Post"
  },
  tampabay: {
    external_id: "tampabay",
    display_name: "Tampa Bay Times"
  },
  unionleader: {
    external_id: "unionleader",
    display_name: "New Hampshire Union Leader"
  },
  usatoday: {
    external_id: "usatoday",
    display_name: "USA Today"
  },
  usnews: {
    external_id: "usnews",
    display_name: "US News & World Report"
  },
  wsj: {
    external_id: "wsj",
    display_name: "Wall Street Journal"
  },
  washingtonpost: {
    external_id: "washingtonpost",
    display_name: "Washington Post"
  }
};
