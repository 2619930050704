import styled from "@emotion/styled";
import { Colors } from "../../css/styles.modulecss";

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px 5px;
`;
export const LogoText = styled.a`
  color: ${Colors.light_purple};
  text-transform: uppercase;
  margin: 0 15px;
  text-decoration: none;
  letter-spacing: 4px;
  &:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
  },
`;
