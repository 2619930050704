import styled from "@emotion/styled";
import { makeStyles } from "mui-styles";
import { Colors, FontFamily, FontSize } from "../../../css/styles.modulecss";

export const modalContentStyles = makeStyles(() => ({
  modalPlacement: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  buttonPlacement: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px"
  },
  dialogPaper: {
    minHeight: "35vh",
    minWidth: "35vh",
    borderRadius: "15px"
  }
}));

export const TagsSubtitle = styled.div`
  display: flex;
  padding: 10px;
  font-family: ${FontFamily.fontRoboto};
  font-size: ${FontSize.title};
  margin-left: 15px;
`;

export const TagsTitle = styled.div`
  display: flex;
  padding: 5px;
  font-weight: bold;
  font-family: ${FontFamily.fontRoboto};
  font-size: ${FontSize.title};
  margin-left: 15px;
`;

export const AddTagButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0px 0px 2.5px;
`;

export const AddTagButton = styled.button`
  display: flex 1;
  align-items: stretch;
  width: 100%;
  padding: 12px 25px 20px;
  border: none;
  border-radius: 10px;
  background-color: #d3b2e3;
  border: 2.5px solid #d3b2e3;
  color: ${Colors.black};
  font-size: ${FontSize.smallText};
  font-weight: 600;

  :hover {
    background-color: ${Colors.white};
    border: 2.5px solid #af71d1;
    color: ${Colors.black};
  }
`;

export const TagIcon = styled.div`
  display: flex 1;
  justify-content: flex-end;
  align-items: center;
  border: 2.25px solid ${Colors.dark_grey};
  opacity: 75%;
  border-radius: 10px;
  height: 34px;
  width: 90px;
  margin: 10px;
`;

export const IconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  height: 18px;
  width: 18px;
  border: 1px solid ${Colors.dark_grey};
  color: ${Colors.white};
  background-color: ${Colors.dark_grey};
  font-family: ${FontFamily.fontRoboto};
  font-size: ${FontSize.smallText};
  border-radius: 50%;
`;

export const ButtonText = styled.span`
  position: "absolute";
  top: "50%";
  left: "50%";
  transform: "translate(-50%, -50%)";
`;
