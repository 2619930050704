import React from "react";
import { CanvasComponent } from "./CanvasComponent";
import {
  ViewCanvasContainerBox,
  ViewCanvasFrame,
  StudentName,
  canvasContainerContentStyles
} from "./ViewCanvasContainer.modulecss";
import {
  ArticleData,
  Canvas,
  getCanvasForUser,
  getArticlesByCanvas,
  StoredArticle,
  getArticleById
} from "services/article-service";
import { withRouter, useParams } from "react-router-dom";
import { Modal } from "@mui/material";
import { UserInfo } from "services/login-service";
import { getStudent } from "services/classroom-service";
import { ArticleDetailCard } from "./ArticleDetailCard";

export const ViewCanvasContainer = withRouter(() => {
  const classes = canvasContainerContentStyles();
  const params = useParams<{ userId: string | undefined }>();
  const student_id = Number(params.userId);

  const [selectedArticle, setSelectedArticle] =
    React.useState<StoredArticle | null>(null);
  const [canvas, setCanvas] = React.useState<Canvas | null>(null);
  const [canvasArticles, setCanvasArticles] = React.useState<
    ArticleData[] | null
  >(null);
  const [student, setStudent] = React.useState<UserInfo | null>(null);

  React.useEffect(() => {
    const fetchData = async () => {
      const canvasObj = await getCanvasForUser(student_id);
      const articles = await getArticlesByCanvas(canvasObj.id);
      const studentObj = await getStudent(student_id);
      setCanvas(canvasObj);
      setCanvasArticles(articles);
      setStudent(studentObj);
    };
    fetchData();
  }, []);

  const displayArticle = async (article: ArticleData) => {
    const storedArticle = await getArticleById(article.id);
    setSelectedArticle(storedArticle);
  };
  const closeArticleDetail = () => {
    setSelectedArticle(null);
  };

  const halfScreen = true; // TODO: if we scrap iframes, actually delete the code that uses this
  // selectedArticle != null &&
  // selectedArticle.source != null &&
  // HIDE_IFRAME_WHITELIST.has(selectedArticle.source.name);
  return (
    <ViewCanvasFrame>
      {student ? (
        <StudentName>
          {`${student.first_name} ${student.last_name}`}{" "}
        </StudentName>
      ) : null}
      <ViewCanvasContainerBox>
        <CanvasComponent
          handleOrganizerModelChange={() => {}}
          organizerOptionsModel={false}
          canvasArticles={canvasArticles}
          canvas={canvas}
          deleteCanvasArticle={() => {}}
          refreshArticles={() => {}}
          onSelectArticle={displayArticle}
          updateArticlePosition={() => {}}
          clearCanvasModal={false}
          handleCanvasClearModal={() => {}}
          isReadOnly
          articleList={canvasArticles}
        />
      </ViewCanvasContainerBox>
      {selectedArticle !== null ? (
        <Modal
          open={selectedArticle !== null}
          onClose={closeArticleDetail}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: halfScreen ? "50%" : "95%"
          }}
        >
          <div className={classes.modalPlacement}>
            <ArticleDetailCard
              article={selectedArticle}
              refreshArticles={() => {}}
              closeArticleDetailCard={closeArticleDetail}
              halfScreen={halfScreen}
              isReadOnly
            />
          </div>
        </Modal>
      ) : null}
    </ViewCanvasFrame>
  );
});
