import React from "react";
import { Switch, Route } from "react-router-dom";
import { Dashboard } from "../authed/Dashboard/Dashboard";
import { ApplicationLayout } from "./ApplicationLayout";
import { getNewsList, ArticleData } from "services/article-service";
import { handleOnErrorToastify } from "utility/toastify";
import { Timeline } from "../authed/Timeline/Timeline";
import { UserDashboard } from "./Courses/UserDashboard";
import { ViewCanvasContainer } from "./Dashboard/ViewCanvasContainer";

interface RouterAuthState {
  searchResults: ArticleData[];
  fetchingSearchResults: boolean;
  searchResultsError: string | null;
  isSearchPerformed: boolean;
}

export const RouterAuth = () => {
  const [state, setState] = React.useState<RouterAuthState>({
    searchResults: [],
    fetchingSearchResults: false,
    searchResultsError: null,
    isSearchPerformed: false
  });
  const [searchValue, setSearchValue] = React.useState("");

  const onSearch = async (queryTo: string, useBing: boolean) => {
    setState({
      ...state,
      searchResultsError: null,
      fetchingSearchResults: true
    });
    try {
      const articles = queryTo && (await getNewsList(queryTo, useBing));
      setState({
        ...state,
        searchResults: articles,
        fetchingSearchResults: false,
        isSearchPerformed: true
      });
    } catch (error) {
      handleOnErrorToastify(error.error || error.message);
      setState({
        ...state,
        fetchingSearchResults: false,
        searchResultsError: error.error || error.message,
        isSearchPerformed: true
      });
    }
  };

  return (
    <ApplicationLayout>
      <Switch>
        <Route path="/user/dashboard">
          <UserDashboard />
        </Route>
        <Route path="/user/explore">
          <Dashboard
            articles={state.searchResults}
            fetching={state.fetchingSearchResults}
            isSearchPerformed={state.isSearchPerformed}
            onSearch={onSearch}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        </Route>
        <Route path="/user/timeline">
          <Timeline
            articles={state.searchResults.length > 0 ? state.searchResults : []}
            fetching={state.fetchingSearchResults}
            isSearchPerformed={state.isSearchPerformed}
            onSearch={onSearch}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        </Route>
        <Route path="/user/canvas/:userId">
          <ViewCanvasContainer />
        </Route>
      </Switch>
    </ApplicationLayout>
  );
};
