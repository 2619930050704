import React from "react";
import { Formik, Field } from "formik";
import { Bounce } from "react-activity";
import { useHistory } from "react-router-dom";

import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

import { SignupSchema } from "../../../utility/validation-schema/SignupSchema";
import { signUp } from "../../../services/login-service";

import { CommonHtmlElement, Colors } from "../../../css/styles.modulecss";

import {
  signupFormStyles,
  signupHelperTextStyles,
  FormWrapper,
  Row,
  Column,
  LoginLink,
  ButtonRow,
  CheckBoxContainer
} from "./Signup.modulecss";

const registeringAsOptions = [
  {
    value: "",
    label: "Select one"
  },
  {
    value: "Student",
    label: "Student"
  },
  {
    value: "Educator",
    label: "Educator"
  }
];

interface SignUpState {
  username: string;
  email: string;
  password: string;
  passwordConfirm: string;
  termsConditionsConfirm: boolean;
}

export const Signup = (): JSX.Element => {
  const classes = signupFormStyles();
  const helperTestClasses = signupHelperTextStyles();

  const history = useHistory();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");

  React.useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }

    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage("");
        history.push("/login");
      }, 3000);
    }
  }, [errorMessage, successMessage, history]);

  const onSubmitLoginHandler = async (creds: SignUpState, { setErrors }) => {
    try {
      const userResponse = await signUp(creds);
      if (userResponse && userResponse.id !== null) {
        setSuccessMessage(
          "Congratulations, your account has been successfully created."
        );
      }

      if (userResponse && userResponse.non_field_errors) {
        setErrorMessage("The fields may not be a blank or ");
      }
    } catch (e) {
      setErrors({});
      setErrorMessage("a network error occured");
    }
  };

  return (
    <>
      <Typography>
        <CommonHtmlElement.Title>Mucktracker</CommonHtmlElement.Title>
        <CommonHtmlElement.Subtitle>
          Please complete to create your account.
        </CommonHtmlElement.Subtitle>
      </Typography>
      <FormWrapper>
        <Formik
          initialValues={{
            first_name: "",
            last_name: "",
            username: "",
            email: "",
            password: "",
            passwordConfirm: "",
            register_as: "",
            termsConditionsConfirm: false
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, { setErrors }) => {
            onSubmitLoginHandler(values, { setErrors });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Column>
                  <TextField
                    label="First name"
                    type="text"
                    name="first_name"
                    id="first_name-muck"
                    fullWidth={true}
                    autoComplete="off"
                    margin="normal"
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.first_name && touched.first_name ? true : false
                    }
                    helperText={
                      errors.first_name &&
                      touched.first_name &&
                      errors.first_name
                    }
                    FormHelperTextProps={{ classes: helperTestClasses }}
                    InputProps={{
                      className: classes.multilineColor
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.label,
                        error: classes.erroredLabel,
                        focused: classes.focusedLabel
                      }
                    }}
                  />
                </Column>
                <Column>
                  <TextField
                    label="Last name"
                    type="text"
                    name="last_name"
                    id="last_name-muck"
                    fullWidth={true}
                    autoComplete="off"
                    margin="normal"
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.last_name && touched.last_name ? true : false}
                    helperText={
                      errors.last_name && touched.last_name && errors.last_name
                    }
                    FormHelperTextProps={{ classes: helperTestClasses }}
                    InputProps={{
                      className: classes.multilineColor
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.label,
                        error: classes.erroredLabel,
                        focused: classes.focusedLabel
                      }
                    }}
                  />
                </Column>
              </Row>
              <Row>
                <TextField
                  label="Email"
                  type="email"
                  name="email"
                  id="email-basic"
                  fullWidth={true}
                  autoComplete="off"
                  margin="normal"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.email && touched.email ? true : false}
                  helperText={errors.email && touched.email && errors.email}
                  FormHelperTextProps={{ classes: helperTestClasses }}
                  InputProps={{
                    className: classes.multilineColor
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      error: classes.erroredLabel,
                      focused: classes.focusedLabel
                    }
                  }}
                />
              </Row>
              <Row>
                <TextField
                  type="text"
                  id="username-muck"
                  label="Username"
                  name="username"
                  error={errors.username && touched.username ? true : false}
                  helperText={
                    errors.username && touched.username && errors.username
                  }
                  FormHelperTextProps={{ classes: helperTestClasses }}
                  fullWidth={true}
                  autoComplete="off"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="normal"
                  InputProps={{
                    className: classes.multilineColor
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      error: classes.erroredLabel,
                      focused: classes.focusedLabel
                    }
                  }}
                />
              </Row>
              <Row>
                <TextField
                  type="password"
                  id="password-muck"
                  label="Password"
                  name="password"
                  error={errors.password && touched.password ? true : false}
                  helperText={
                    errors.password && touched.password && errors.password
                  }
                  FormHelperTextProps={{ classes: helperTestClasses }}
                  fullWidth={true}
                  autoComplete="off"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="normal"
                  InputProps={{
                    className: classes.multilineColor
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      error: classes.erroredLabel,
                      focused: classes.focusedLabel
                    }
                  }}
                />
              </Row>
              <Row>
                <TextField
                  type="password"
                  id="confirm-password-muck"
                  label="Confirm Password"
                  name="passwordConfirm"
                  error={
                    errors.passwordConfirm && touched.passwordConfirm
                      ? true
                      : false
                  }
                  helperText={
                    errors.passwordConfirm &&
                    touched.passwordConfirm &&
                    errors.passwordConfirm
                  }
                  FormHelperTextProps={{ classes: helperTestClasses }}
                  fullWidth={true}
                  autoComplete="off"
                  value={values.passwordConfirm}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="normal"
                  InputProps={{
                    className: classes.multilineColor
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      error: classes.erroredLabel,
                      focused: classes.focusedLabel
                    }
                  }}
                />
              </Row>
              <Row style={{ marginTop: 25, marginBottom: 15 }}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Registering as"
                  name="register_as"
                  fullWidth={true}
                  error={
                    errors.register_as && touched.register_as ? true : false
                  }
                  helperText={
                    errors.register_as &&
                    touched.register_as &&
                    errors.register_as
                  }
                  FormHelperTextProps={{ classes: helperTestClasses }}
                  value={values.register_as}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    className: classes.multilineColor
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      error: classes.erroredLabel,
                      focused: classes.focusedLabel
                    }
                  }}
                >
                  {registeringAsOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Row>
              <Row>
                <CheckBoxContainer>
                  <Field
                    type="checkbox"
                    fullWidth={true}
                    margin="normal"
                    checked={values.termsConditionsConfirm}
                    onChange={() =>
                      setFieldValue(
                        "termsConditionsConfirm",
                        !values.termsConditionsConfirm
                      )
                    }
                  />
                  {touched.termsConditionsConfirm &&
                    errors.termsConditionsConfirm && (
                      <div style={{ color: Colors.red }}>
                        {errors.termsConditionsConfirm}
                      </div>
                    )}
                  <a
                    href={"https://www.mucktracker.com/terms.html"}
                    target="_blank"
                    style={{ color: Colors.bright_blue }}
                    rel="noreferrer"
                  >
                    Terms of Use
                  </a>
                  {" and "}
                  <a
                    href={"https://www.mucktracker.com/privacy-policy.html"}
                    target="_blank"
                    style={{ color: Colors.bright_blue }}
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </CheckBoxContainer>
              </Row>
              {errorMessage && (
                <CommonHtmlElement.HasError
                  style={{ marginTop: 10 }}
                  role="alert"
                >
                  {errorMessage}
                </CommonHtmlElement.HasError>
              )}
              {successMessage && (
                <CommonHtmlElement.HasSuccess
                  style={{ marginTop: 10 }}
                  role="alert"
                >
                  {successMessage}
                </CommonHtmlElement.HasSuccess>
              )}
              <LoginLink
                to={"/login"}
                style={{ color: Colors.bright_blue, fontWeight: 600 }}
              >
                Already have an account? Sign in.
              </LoginLink>
              <ButtonRow>
                <Button
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  <span>Sign Up</span>
                  <Bounce color={Colors.white} animating={isSubmitting} />
                </Button>
              </ButtonRow>
            </form>
          )}
        </Formik>
      </FormWrapper>
    </>
  );
};
