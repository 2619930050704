import React, { useState } from "react";
import {
  FlagBox,
  FlagPole,
  FlagHeader,
  FlagDate,
  FlagSource,
  FlagTotalArticles
} from "./Flags.modulecss";
import moment from "moment";
import { FlagConfig } from "./timeline-helper";
import { SOURCES } from "utility/sources-config";
import IconButton from "@mui/material/IconButton";
import AddCircle from "@mui/icons-material/AddCircle";
import RemoveCircle from "@mui/icons-material/RemoveCircle";
interface FlagProps {
  flagConfig: FlagConfig;
}

export const Flag = (props: FlagProps) => {
  const {
    flagPosition,
    flagArticles,
    totalArticles,
    flagWidthPct,
    flagHeight
  } = props.flagConfig;

  const [expanded, setExpanded] = useState(false);

  const toggle = () => {
    if (flagArticles.length === 1) {
      return;
    }
    setExpanded(!expanded);
  };

  const reversedFlagArray = [...flagArticles].reverse();
  const teaserArticle = reversedFlagArray[0];

  return (
    <>
      <FlagBox
        position={`${flagPosition}`}
        widthPct={expanded ? flagWidthPct * 2 : flagWidthPct}
        heightOffset={flagHeight}
        expanded={expanded}
        aria-expanded={expanded}
        aria-setsize={reversedFlagArray.length}
      >
        {expanded ? (
          <>
            <IconButton
              aria-label="collapse"
              style={{
                position: "sticky",
                top: "0",
                right: "0",
                float: "right"
              }}
              onClick={toggle}
            >
              <RemoveCircle />
            </IconButton>

            {reversedFlagArray.map((value, index) => (
              <div aria-posinset={index} key={index}>
                <FlagDate>
                  {moment(value.published_at)
                    .utc(true)
                    .local()
                    .format("MMM DD YYYY")}
                </FlagDate>
                <a
                  href={value.url}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  rel="noreferrer"
                >
                  <FlagHeader>{value.title}</FlagHeader>
                </a>
                <div>
                  <FlagSource>
                    {value.source &&
                    value.source.name &&
                    SOURCES[value.source.name]
                      ? SOURCES[value.source.name].display_name
                      : ""}{" "}
                  </FlagSource>
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            <FlagDate>
              {moment(teaserArticle.published_at)
                .utc(true)
                .local()
                .format("MMM DD YYYY")}
            </FlagDate>
            {totalArticles - 1 === 0 ? null : (
              <>
                <IconButton
                  aria-label="expand"
                  style={{ float: "right" }}
                  onClick={toggle}
                >
                  <AddCircle />
                </IconButton>
              </>
            )}

            <a
              href={teaserArticle.url}
              target="_blank"
              style={{ textDecoration: "none" }}
              rel="noreferrer"
            >
              <FlagHeader>{teaserArticle.title}</FlagHeader>
            </a>
            <div>
              <FlagSource>
                {teaserArticle.source &&
                teaserArticle.source.name &&
                SOURCES[teaserArticle.source.name]
                  ? SOURCES[teaserArticle.source.name].display_name
                  : ""}
              </FlagSource>
              {totalArticles - 1 === 0 ? (
                <></>
              ) : (
                <FlagTotalArticles onClick={toggle}>
                  +{totalArticles - 1} articles
                </FlagTotalArticles>
              )}
            </div>
          </>
        )}
      </FlagBox>
      <FlagPole position={`${flagPosition}`} heightOffset={flagHeight} />
    </>
  );
};
