import styled from "@emotion/styled";
import { Colors, FontFamily, FontSize } from "../../../css/styles.modulecss";

export const DashboardWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 100px;
  height: 100%;
  width: 100%;
`;

export const ClassRoomListWrapper = styled.div`
  margin-left: 10px;
  display: flex;
  width: 40%;
  margin-right: 10px;
  flex-direction: column;
  @media (max-width: 991px) {
    width: 100%;
  }
  height: calc(100vh - 100px);
`;

export const ClassRoomListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 95%;
  height: 33%;
  align-content: flex-start;
  border: 1px solid ${Colors.black};
  border-radius: 15px;
  padding: 10px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 20px;
    height: 14px;
  }
  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: ${Colors.black};
  }
`;

export const ClassRoom = styled.div`
  margin: 5px;
  width: 100%;
  font-family: ${FontFamily.fontRoboto};
  font-size: ${FontSize.largeText};
  border: 5px solid ${Colors.black};
  border-radius: 10px;
  height: 70px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: row;
`;

export const ClassroomName = styled.div`
  font-family: ${FontFamily.fontRoboto};
  font-size: ${FontSize.heading};
  font-weight: bold;
  display: flex;
  height: 60%;
  margin-left: 20px;
`;

export const InviteCode = styled.div`
  font-family: ${FontFamily.fontRoboto};
  font-size: ${FontSize.smallText};
  display: flex;
  height: 30%;
  margin-left: 20px;
  margin-top: 3px;
`;

export const ClassDetailsContainer = styled.div`
  font-family: ${FontFamily.fontRoboto};
  display: flex;
  flex-direction: column;
`;

export const IconContainer = styled.div`
  width: 30px;
  margin: 0px 5px;
`;

export const NoSelectedClass = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.grey};
  height: 100%;
  width: 55%;
  justify-content: center;
  align-items: center;
  font-size: ${FontSize.title};
  color: ${Colors.slate_gray};
  border-radius: 15px;
  text-align: center;
  padding: 40px;
  height: calc(100vh - 200px);
`;

export const Header = styled.div`
  font-size: ${FontSize.heading};
  color: ${Colors.black};
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 3px;
  font-weight: bold;
  line-height: 30px;
  font-family: ${FontFamily.fontRoboto};
  @media (max-width: 991px) {
    font-size: ${FontSize.heading};
  }
  @media (max-width: 599px) {
    font-size: ${FontSize.largeText};
    line-height: 26px;
  }
`;

export const StudentContainer = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  border: 1px solid ${Colors.black};
  flex-direction: column;
  height: 35%;
  width: 55%;
  justify-content: center;
  align-items: center;
  font-size: ${FontSize.title};
  color: ${Colors.black};
  border-radius: 15px;
  text-align: center;
  padding: 40px;
  overflow: scroll;
`;

export const CourseWelcomeTitle = styled.div`
  font-size: 40px;
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CourseMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: center;
`;
export const CenteredCourseMessageText = styled.div`
  font-size: ${FontSize.heading};
  justify-content: center;
  align-items: center;
`;

export const CourseMessageBlock = styled.div`
  display: flex;
  border: 1px solid ${Colors.black};
  flex-direction: column;
  width: 60%;
  font-size: ${FontSize.title};
  color: ${Colors.black};
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 30px;
`;

export const FullWidthCourseMessageBlock = styled.div`
  display: flex;
  border: 1px solid ${Colors.black};
  flex-direction: column;
  width: 95%;
  font-size: ${FontSize.title};
  color: ${Colors.black};
  border-radius: 15px;
  padding: 30px;
  margin-top: 50px;
`;

export const InviteFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const InviteButton = styled.button`
  display: flex;
  background-color: ${Colors.black};
  color: ${Colors.white};
  border: 0;
  padding: 0;
  text-align: center;
  font-size: ${FontSize.smallText};
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 4px;
  font-family: ${FontFamily.fontRoboto};
  text-transform: uppercase;
  letter-spacing: 1.43px;
  padding: 5px;
  :hover {
    background-color: ${Colors.yellow};
  }
  :focus,
  :active {
    outline: 0;
  }
  @media (max-width: 1400px) {
    height: 40px;
    letter-spacing: 1px;
  }
  @media (max-width: 1199px) {
    font-size: 13px;
  }
`;

export const ExploreMessageContainer = styled.div`
  flex-direction: row;
  display: flex;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const InviteMessageText = styled.div`
  font-size: ${FontSize.heading};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const CourseMessageText = styled.div`
  font-size: ${FontSize.heading};
  display: flex;
  width: 100%;
  text-align: left;
  margin-right: 10px;
`;

export const TopicMessageText = styled.div`
  font-size: ${FontSize.heading};
  font-weight: bold;
  text-align: left;
  width: 100%;
  margin-bottom: 20px;
`;
export const TopicMessageTextLink = styled.div`
  font-size: ${FontSize.largeText};
  text-align: left;
  width: 100%;
  font-weight: bold;
`;

export const OutlineButton = styled.div`
  font-size: ${FontSize.largeText};
  padding: 0px 10px;
  margin: 10px 0px;
  border-radius: 10px;
  border: 1px solid ${Colors.black};
  :hover {
    cursor: pointer;
  }
`;

export const ExploreButtonContainer = styled.div`
  margin: auto;
  float: right;
  width: 20%;
  text-align: center;
  display: flex;
  align-items: center;
`;

export const ExploreButton = styled.button`
  display: inline-block;
  background-color: ${Colors.black};
  color: ${Colors.white};
  font-size: ${FontSize.smallText};
  cursor: pointer;
  border-radius: 4px;
  font-family: ${FontFamily.fontRoboto};
  width: 20vh;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1.43px;
  padding: 10px;
  :hover {
    background-color: ${Colors.yellow};
  }
  :focus,
  :active {
    outline: 0;
  }
  @media (max-width: 1400px) {
    height: 40px;
    letter-spacing: 1px;
  }
  @media (max-width: 1199px) {
    font-size: 13px;
  }
`;

export const ButtonContainer = styled.div`
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
