import React from "react";
import moment from "moment";
import {
  DashboardWrapper,
  ClassHeader,
  StudentItem,
  DeleteButton,
  StudentUsername,
  StudentItemList,
  NoStudent,
  RightButtons,
  ViewCanvasButton,
  DeleteConfirmationText,
  ButtonContainer,
  classDetailsContentStyles,
  StudentLastActive,
  StudentInfo
} from "./ClassDetails.modulecss";
import { UserInfo } from "services/login-service";
import { Modal } from "@mui/material";
import { CommonHtmlElement } from "css/styles.modulecss";

interface ClassDetailsProps {
  classroomInformation: any;
  classroomStudents: UserInfo[];
  deleteHandler: (classroom_id: number, student_id: number) => any;
}
export const ClassDetails = (props: ClassDetailsProps) => {
  const classes = classDetailsContentStyles();
  const [studentIdToDelete, setStudentIdToDelete] = React.useState<
    number | null
  >(null);

  return (
    <DashboardWrapper>
      <ClassHeader>{props.classroomInformation.name}</ClassHeader>
      {props.classroomStudents !== null &&
      Object.keys(props.classroomStudents).length !== 0 ? (
        <StudentItemList>
          {props.classroomStudents.map((item: UserInfo, index) => (
            <StudentItem key={index}>
              <StudentInfo>
                <StudentUsername>{`${item.first_name} ${item.last_name}`}</StudentUsername>
                {item.last_login ? (
                  <StudentLastActive>
                    Last login:{" "}
                    {`(${moment(item.last_login)
                      .utc(true)
                      .local()
                      .format("MMM D h:mmA")})`}
                  </StudentLastActive>
                ) : null}
              </StudentInfo>
              <RightButtons>
                <ViewCanvasButton to={`/user/canvas/${item.id}`}>
                  View Canvas
                </ViewCanvasButton>
                <DeleteButton
                  onClick={() => {
                    setStudentIdToDelete(item.id);
                  }}
                >
                  {String.fromCharCode(10006)}
                </DeleteButton>
              </RightButtons>
            </StudentItem>
          ))}
        </StudentItemList>
      ) : (
        <NoStudent>No students currently enrolled</NoStudent>
      )}
      <Modal
        open={studentIdToDelete !== null}
        onClose={() => {
          setStudentIdToDelete(null);
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div className={classes.modalPlacement}>
          <DeleteConfirmationText>
            Are you sure you want to remove this student from the course?
          </DeleteConfirmationText>
          <ButtonContainer>
            <CommonHtmlElement.CommonButton
              onClick={() => {
                setStudentIdToDelete(null);
              }}
            >
              Cancel
            </CommonHtmlElement.CommonButton>
            <CommonHtmlElement.CommonButton
              onClick={() => {
                if (studentIdToDelete !== null) {
                  props.deleteHandler(
                    props.classroomInformation.id,
                    studentIdToDelete
                  );
                  setStudentIdToDelete(null);
                }
              }}
            >
              OK
            </CommonHtmlElement.CommonButton>
          </ButtonContainer>
        </div>
      </Modal>
    </DashboardWrapper>
  );
};
