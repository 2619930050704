import { deleteHttp, getHttp, postHttp, putHttp } from "./backend";
import moment from "moment";

const ArticleRoutes = {
  newsListAPI: `/api/articles/search/`,
  createNewArticle: `/api/articles/article-list/`,
  createNewSummary: `/api/articles/summarize-list/`,
  editSummary: `/api/articles/summary-details/`,
  removeTag: `/api/articles/remove-article-tag/`,
  tagList: `/api/articles/tag-list/`
};

interface DbModel {
  id: number;
}
const AnnotationRoutes = {
  createNewAnnotation: `/api/articles/annotation-list/`
};

interface Source {
  id?: string;
  name: string;
  provider_display_name?: string;
}

export interface ArticleDataInput {
  external_id: string;
  author: string;
  content_summary: string;
  description: string;
  published_at: string;
  source?: Source;
  title: string;
  url: string;
  url_to_image: string;
  canvas: number | null;
  canvas_x_pct: number;
  canvas_y_pct: number;
  reaction: string;
}

export interface SavedArticle {
  num_summaries: number;
  num_tags: number;
}

export type ArticleData = ArticleDataInput & SavedArticle & DbModel;

interface SummaryData {
  id: number;
  article: number;
  summary: string;
  user: number;
}

export interface StoredArticle extends ArticleData {
  annotations?: any;
  collections?: any;
  tags?: any;
  summaries?: SummaryData | null;
}

export interface Canvas {
  id: number;
}

export const HIDE_IFRAME_WHITELIST = new Set([
  "azcentral",
  "baltimoresun",
  "buzzfeednews",
  "chicagotribune",
  "cnbc",
  "cnn",
  "nypost",
  "nytimes",
  "stlouispost",
  "usatoday",
  "usnews"
]);

export const REACTIONS = [
  { value: "NONE", emoji: "" },
  { value: "SMILE", emoji: "🙂" },
  { value: "CRY", emoji: "😢" },
  { value: "ANGRY", emoji: "😠" },
  { value: "FIRE", emoji: "🔥" },
  { value: "WARNING", emoji: "⚠️" },
  { value: "SHOCK", emoji: "😮" },
  { value: "HEARTS", emoji: "😍" }
];

// get top-headlines News details
export const getNewsList = async (query: string, use_bing: boolean) => {
  const url = `${ArticleRoutes.newsListAPI}?q=${query}${
    use_bing ? "&bing=true" : ""
  }`;
  const results = await getHttp(url);
  return results.data.sort((a: ArticleData, b: ArticleData) => {
    return moment(b.published_at).diff(moment(a.published_at));
  });
};

export const getUniqueTagsForUser = async () => {
  const response = await getHttp(`/api/articles/tag-list/`);
  const tags = response.data.map((articleTag) => articleTag.tag);
  const uniqueTags = tags.filter((v, i, a) => a.indexOf(v) === i);
  return uniqueTags;
};

// Create or Save new article from list
export const saveArticle = async (data: ArticleDataInput) => {
  return await putHttp(ArticleRoutes.createNewArticle, data);
};

export const getArticleById = async (article_id: number) => {
  const articleInfo = await getHttp(`/api/articles/article/${article_id}/`);
  return articleInfo.data;
};

interface CreateSummaryRequestParams {
  summary: string;
  article: number;
  user: number;
}

// Create or Save summary on Article
export const createSummary = async (data: CreateSummaryRequestParams) => {
  return await postHttp(ArticleRoutes.createNewSummary, data);
};

interface EditSummaryRequestParams {
  summary: string;
  article: number;
}

// Edit summary on Article
export const editOnSummary = async (data: EditSummaryRequestParams) => {
  return await putHttp(ArticleRoutes.editSummary, data);
};

interface DeleteSummaryRequestParams {
  article: number;
}

export const deleteSummary = async (data: DeleteSummaryRequestParams) => {
  return await putHttp(`/api/articles/remove-article-summary/`, data);
};

interface AnnotationParams {
  article: number;
  user: number;
  start_index: number;
  end_index: number;
  annotate: string;
}

// Create or Save annotation
export const createAnnotation = async (data: AnnotationParams) => {
  return await postHttp(AnnotationRoutes.createNewAnnotation, data);
};

interface RemoveArticleTagParams {
  tag: string;
  article: number;
  user: number | null;
}

export const removeArticleTag = async (data: RemoveArticleTagParams) => {
  return await postHttp(ArticleRoutes.removeTag, data);
};

interface AddArticleTagParams {
  tag: string;
  article: number;
  user: number | null;
}

export const addArticleTag = async (data: AddArticleTagParams) => {
  return await postHttp(ArticleRoutes.tagList, data);
};

//get or create canvas for user
export const postCanvas = async () => {
  return await postHttp(`/api/articles/canvas_deprecated/`, null);
};

export const getCanvasForUser = async (user_id: number) => {
  const canvas = await getHttp(
    `/api/articles/canvas_deprecated/user/${user_id}/`
  );
  return canvas.data;
};

interface UpdateCanvasParams {
  organizer: string;
  label1: string | null;
  label2: string | null;
  label3: string | null;
  label4: string | null;
}

export const updateCanvas = async (data: UpdateCanvasParams) => {
  return await putHttp(`/api/articles/canvas_deprecated/`, data);
};

export const getArticlesByCanvas = async (canvas_id: number) => {
  const response = await getHttp(`/api/articles/article-list/${canvas_id}/`);
  return response.data;
};

interface ArticleCanvasRemoveParamas {
  canvas: number;
  external_id: string;
}

export const removeCanvasArticle = async (data: ArticleCanvasRemoveParamas) => {
  const response = await postHttp(`/api/articles/remove-article/`, data);
  return response;
};

interface UpdateArticlePositionParams {
  canvas_y_pct: number;
  canvas_x_pct: number;
}

export const updateArticlePosition = async (
  data: UpdateArticlePositionParams,
  article: number
) => {
  const response = await putHttp(`/api/articles/${article}/position/`, data);
  return response;
};

export const clearCanvas = async (canvas_id: string) => {
  const response = await deleteHttp(`/api/articles/canvas/${canvas_id}/`);
  return response;
};

export const updateArticleReaction = async (
  article_id: number,
  reaction: string
) => {
  await putHttp(`/api/articles/${article_id}/reaction/`, { reaction });
};
