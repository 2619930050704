import { toast } from "react-toastify";

export const handleOnErrorToastify = (messageToDisplay: string) => {
  toast.error(messageToDisplay, {
    position: toast.POSITION.TOP_RIGHT
  });
};

export const handleOnSuccessToastify = (messageToDisplay: string) => {
  toast.dark(messageToDisplay, {
    position: toast.POSITION.TOP_RIGHT
  });
};
