import styled from "@emotion/styled";
import { Colors, FontSize } from "css/styles.modulecss";
import { makeStyles } from "mui-styles";

export const canvasContainerContentStyles = makeStyles(() => ({
  modalPlacement: {
    width: "70%",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  }
}));

export const ViewCanvasFrame = styled.div`
  margin: 50px 20px;
  padding-left: 15px;
  flex: 1;
  height: calc(100vh - 200px);
`;

export const StudentName = styled.div`
  font-size: ${FontSize.heading};
`;

export const ViewCanvasContainerBox = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  border-color: ${Colors.dark_grey};
  border-style: solid;
  border-width: 2px;
  border-radius: 15px;
  background-color: ${Colors.white};
  padding: 15px;
  width: 100%;
  height: 100%;
`;
