import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Login } from "./Login/Login";
import { Signup } from "./Signup/Signup";
import { PreauthContainer } from "./PreauthContainer";

export const RouterPreauth = () => {
  return (
    <PreauthContainer>
      <Switch>
        <Route exact path={"/"}>
          <Redirect to="/login" />
        </Route>
        <Route exact path={"/login"}>
          <Login />
        </Route>
        <Route exact path={"/signup"}>
          <Signup />
        </Route>
      </Switch>
    </PreauthContainer>
  );
};
