import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Navbar } from "./Navbar/Navbar";
import { Typography } from "@mui/material";

const isSafariOrChromeOnIOS = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent
);
const isChromeOnIOS = navigator.userAgent.match("CriOS");

interface ApplicationLayoutProps {
  children: any;
}

export const ApplicationLayout = (props: ApplicationLayoutProps) => {
  return (
    <div style={{ display: "flex" }}>
      <Navbar />
      <CssBaseline />
      {isSafariOrChromeOnIOS && !isChromeOnIOS ? (
        <div
          style={{
            height: 400,
            width: "100%",
            marginLeft: 240,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Typography>
            Mucktracker isn't currently available on Safari. Please try again on
            Chrome or Firefox.
          </Typography>
          <Typography>
            For support, please e-mail us at{" "}
            <a href="mailto:support@mucktracker.com">support@mucktracker.com</a>
          </Typography>
        </div>
      ) : (
        props.children
      )}
    </div>
  );
};
