import React from "react";
import Background from "../../images/img.jpg";

import { Logo } from "../reusable/Logo";
import {
  Header,
  MainWelcomeWrapper,
  WelcomeWrapper,
  WelcomeBlock,
  MainContainer
} from "./PreauthContainer.module";

interface PreauthContainerProps {
  children: any;
}

export const PreauthContainer = (props: PreauthContainerProps): JSX.Element => {
  return (
    <div>
      <Header>
        <Logo />
      </Header>
      <MainWelcomeWrapper style={{ backgroundImage: `url(${Background})` }}>
        <MainContainer>
          <WelcomeWrapper>
            <WelcomeBlock>{props.children}</WelcomeBlock>
          </WelcomeWrapper>
        </MainContainer>
      </MainWelcomeWrapper>
    </div>
  );
};
