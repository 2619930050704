import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Colors, FontSize } from "../../../css/styles.modulecss";
import { makeStyles } from "mui-styles";

export const signupFormStyles = makeStyles(() => ({
  multilineColor: {
    color: Colors.black,
    "&::after": {
      border: `1px solid ${Colors.black}`
    },
    "&::before": {
      borderBottom: `1px solid ${Colors.black}`
    },
    "&:hover:not($disabled):before": {
      borderBottom: `1px solid ${Colors.black}!important`,
      "@media (hover: none)": {
        borderBottom: `1px solid ${Colors.black}`
      }
    }
  },
  textField: {
    position: "relative"
  },
  label: {
    "&$erroredLabel": {
      color: Colors.red
    },
    "&$focusedLabel": {
      color: Colors.black
    },
    color: Colors.black,
    fontSize: 15
  },
  focusedLabel: {},
  erroredLabel: {}
}));

export const signupHelperTextStyles = makeStyles(() => ({
  root: {
    "&$error": {
      position: "absolute",
      top: "100%",
      left: "0"
    }
  },
  error: {}
}));

const FormWrapper = styled.div`
  max-width: 430px;
  margin: 0 auto;
  width: 100%;
`;

const CommonLinks = styled.ul`
  display: flex;
  width: 100%;
  justify-content: center;
  bottom: 10px;
  left: 0;
  padding: 0;
  margin: 0;
`;

const ButtonRow = styled.div`
  display: flex;
  margin-top: 30px;
  margin-bottom: 50px;
  button {
    background-color: ${Colors.yellow};
    border: 1px solid ${Colors.yellow};
    color: ${Colors.black};
    text-decoration: none;
    width: 48%;
    margin: 0 auto;
    text-transform: none;
    font-size: ${FontSize.largeText};
    :hover {
      background-color: transparent;
      border: 1px solid ${Colors.black};
    }
    @media (max-width: 767px) {
      font-size: ${FontSize.largeText};
    }
  }
`;
const FormTitle = styled.h4`
  margin: 0;
  font-weight: 600;
  text-transform: capitalize;
  line-height: normal;
  font-size: ${FontSize.smallText};
  text-align: left;
  color: ${Colors.black};
`;
const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 479px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  width: 48%;
  margin-right: 4%;
  display: flex;
  :last-child {
    margin-right: 0;
  }
  @media (max-width: 479px) {
    width: 100%;
    margin-right: 0;
  }
`;
const LoginLink = styled(Link)`
  color: ${Colors.yellow};
  text-decoration: none;
  font-size: ${FontSize.smallText};
  :hover {
    color: ${Colors.yellow_hover};
  }
  margin-top: 20px;
`;

const CheckBoxContainer = styled.div`
  width: 100%;
  flex-direction: row;
  margin-bottom: 20px;
`;

export {
  FormWrapper,
  CommonLinks,
  FormTitle,
  Row,
  Column,
  LoginLink,
  ButtonRow,
  CheckBoxContainer
};
