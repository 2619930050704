import styled from "@emotion/styled";
import { Colors } from "../../../css/styles.modulecss";
import { makeStyles } from "mui-styles";

// Material-UI Styles
export const dashboardContentStyles = makeStyles(() => ({
  content: {
    flexGrow: 1,
    marginTop: "51px",
    overflow: "auto",
    "@media(max-width:599px)": {
      marginTop: "95px"
    }
  },
  modalPlacement: {
    width: "70%",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  }
}));

// Styled Components
export const DashboardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const SearchResultWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 93vh;
  @media (max-width: 991px) {
    flex-direction: column;
    margin-top: 0;
  }
`;

export const CanvasWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  @media (max-width: 991px) {
    width: 100%;
    margin-top: 20px;
  }
`;

export const SearchResultCard = styled.div`
  display: flex;
  width: 100%;
  height: 93vh;
  flex-direction: column;
`;

export const CanvasHeaderButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;

export const OrganizerButton = styled.button`
  height: 35px;
  width: 150px;
  font-size: 14px;
  padding: 5px;
  border-color: ${Colors.dark_grey};
  border-style: solid;
  border-width: 1px;
  background-color: ${Colors.white};
  border-radius: 15px;
  z-index: 2;
`;

export const ButtonContainer = styled.div`
  padding: 0 5px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const CanvasButtonContainer = styled.div`
  padding: 15px 5px;
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
`;
