import React, { useEffect } from "react";
import moment from "moment";

import {
  ResultCardWrapper,
  ResultLists,
  ContentHeader,
  ArticleDetailBody,
  ResultCard,
  SearchIMG,
  SearchContent,
  ContentHead,
  DateTime,
  News,
  ArticleContent,
  ArticleInputs,
  DeleteButton,
  ReadOnlySummary,
  ArticleInputsHalfScreen,
  SummaryContainer,
  HalfScreenRightContainer,
  ReadOnlyHalfScreenSummary,
  ReadArticleText,
  ReadArticleButton,
  TagAndReactionContainer,
  EmoticonContainer,
  EmoticonText
} from "./ArticleDetailCard.modulecss";
import { Logos } from "./Logos";
import {
  StoredArticle,
  createSummary,
  editOnSummary,
  deleteSummary,
  REACTIONS,
  updateArticleReaction
} from "services/article-service";
import { UserStateContext } from "contexts/UserContext";
import { Bounce } from "react-activity";

import AddTag from "./AddTag";
import Tags from "./Tags";
import Summary from "./Summary";

import { removeArticleTag, addArticleTag } from "services/article-service";
import { Typography, Select, MenuItem } from "@mui/material";

interface ArticleDetailCardProps {
  article: StoredArticle;
  refreshArticles: () => void;
  closeArticleDetailCard: () => void;
  halfScreen: boolean;
  isReadOnly?: boolean;
}

export const ArticleDetailCard = (props: ArticleDetailCardProps) => {
  const { article, refreshArticles, halfScreen } = props;
  const userState = React.useContext(UserStateContext);
  const articleTags = article.tags.map((data) => data.tag);
  const [tags, setTags] = React.useState<string[]>(articleTags);
  const [summary, setSummary] = React.useState<string>(
    article.summaries && article.summaries.summary
      ? article.summaries.summary
      : ""
  );
  const [summaryError, setSummaryError] = React.useState<string | null>(null);
  const [tagModalOpen, setTagModalOpen] = React.useState<boolean>(false);
  const [articleVisible, setArticleVisible] = React.useState<boolean>(false);
  const [reaction, setReaction] = React.useState<string>(
    article.reaction || "NONE"
  );

  useEffect(() => {
    const articleTags = article.tags.map((data) => data.tag);
    setTags(articleTags);
    setSummary(
      article.summaries && article.summaries.summary
        ? article.summaries.summary
        : ""
    );
  }, [props.article]);
  // Hardcoded 2s delay hiding the article to mask latency
  useEffect(() => {
    setTimeout(() => {
      setArticleVisible(true);
    }, 2000);
  }, []);

  const deleteTags = async (tag_name: string) => {
    const data = {
      tag: tag_name,
      article: article.id,
      user: userState.id
    };
    await removeArticleTag(data);
    setTags(
      tags.filter((t: string) => {
        return t !== tag_name;
      })
    );
    await refreshArticles();
  };

  const addTags = async (tag_name: string) => {
    const data = {
      tag: tag_name,
      article: article.id,
      user: userState.id
    };
    await addArticleTag(data);
    setTags([...tags, tag_name]);
    await refreshArticles();
  };

  const handleSetSummary = async (summary: string) => {
    const data = {
      summary,
      article: article.id
    };
    if (article.summaries === null && userState.id) {
      const data = {
        summary,
        article: article.id,
        user: userState.id
      };
      try {
        await createSummary(data);
      } catch (error) {
        setSummaryError(error.error || error.message);
      }
    } else {
      try {
        if (summary === "") {
          await handleDeleteSummary();
        } else {
          await editOnSummary(data);
        }
      } catch (error) {
        setSummaryError(error.error || error.message);
      }
    }
    setSummaryError(null);
    await refreshArticles();
  };

  const handleDeleteSummary = async () => {
    const data = {
      article: article.id
    };
    await deleteSummary(data);
  };

  const handleClickOpenAddTag = () => {
    setTagModalOpen(true);
  };

  const handleCloseAddTag = () => {
    setTagModalOpen(false);
  };

  const handleSelectReaction = async (event: any) => {
    const newReaction = event.target.value;
    updateArticleReaction(article.id, newReaction);
    setReaction(newReaction);
  };

  const articleUrlAsHttps = article.url.replace("http:", "https:");
  return (
    <>
      <ResultCard isFilled={article !== undefined}>
        <ResultCardWrapper>
          <ResultLists>
            <DeleteButton
              onClick={props.closeArticleDetailCard}
              aria-label="Close Article More Details"
            >
              {String.fromCharCode(10006)}
            </DeleteButton>
            <ContentHeader>
              <SearchIMG style={{ justifyContent: "center" }}>
                <Logos
                  source_name={article.source ? article.source.name : ""}
                  provider_display_name={
                    article.source && article.source.provider_display_name
                      ? article.source.provider_display_name
                      : ""
                  }
                />
              </SearchIMG>
              <SearchContent>
                <a
                  href={article.url}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  rel="noreferrer"
                >
                  <ContentHead>{article.title}</ContentHead>
                </a>
                <DateTime>
                  {article.published_at &&
                    moment(article.published_at)
                      .utc(true)
                      .local()
                      .format("MMM. DD, YYYY h:mm")}
                </DateTime>
                <News aria-label="Article Description">
                  {article.description}
                </News>
              </SearchContent>
              {tags.length > 0 ? (
                <Tags tags={tags} deleteTags={deleteTags} />
              ) : null}
            </ContentHeader>
          </ResultLists>

          <ArticleDetailBody>
            {halfScreen ? (
              <>
                {props.isReadOnly ? (
                  <ReadOnlyHalfScreenSummary
                    style={{
                      fontStyle:
                        summary && summary.length > 0 ? "normal" : "italic"
                    }}
                  >
                    {summary && summary.length > 0
                      ? summary
                      : "<No notes were added>"}
                  </ReadOnlyHalfScreenSummary>
                ) : (
                  <ArticleInputsHalfScreen>
                    <Summary
                      savedSummary={summary}
                      setSummary={handleSetSummary}
                      errorMessage={summaryError}
                    />
                    <HalfScreenRightContainer>
                      <a
                        href={article.url}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                        rel="noreferrer"
                      >
                        <ReadArticleButton>
                          <ReadArticleText>
                            Read this article in a new tab
                          </ReadArticleText>
                        </ReadArticleButton>
                      </a>
                      <TagAndReactionContainer>
                        <EmoticonContainer>
                          <EmoticonText>Add an emoji</EmoticonText>
                          <Select
                            value={reaction}
                            onChange={handleSelectReaction}
                          >
                            {REACTIONS.map((item, index) => (
                              <MenuItem value={item.value} key={index}>
                                {item.emoji}
                              </MenuItem>
                            ))}
                          </Select>
                        </EmoticonContainer>

                        <AddTag
                          addTags={addTags}
                          tagModalState={tagModalOpen}
                          handleClose={handleCloseAddTag}
                          handleOpen={handleClickOpenAddTag}
                        />
                      </TagAndReactionContainer>
                    </HalfScreenRightContainer>
                  </ArticleInputsHalfScreen>
                )}
              </>
            ) : (
              <>
                <ArticleInputs>
                  {props.isReadOnly ? (
                    <ReadOnlySummary
                      style={{
                        fontStyle:
                          summary && summary.length > 0 ? "normal" : "italic"
                      }}
                    >
                      {summary && summary.length > 0
                        ? summary
                        : "<No notes were added>"}
                    </ReadOnlySummary>
                  ) : (
                    <>
                      <SummaryContainer>
                        <Summary
                          savedSummary={summary}
                          setSummary={handleSetSummary}
                          errorMessage={summaryError}
                        />
                      </SummaryContainer>
                      <AddTag
                        addTags={addTags}
                        tagModalState={tagModalOpen}
                        handleClose={handleCloseAddTag}
                        handleOpen={handleClickOpenAddTag}
                      />
                    </>
                  )}
                </ArticleInputs>
                <ArticleContent>
                  <Typography
                    style={{ display: articleVisible ? "block" : "none" }}
                  >
                    Some news sites can't load inside Mucktracker.
                  </Typography>
                  <Typography
                    id="articleLinkLabel"
                    style={{ display: articleVisible ? "block" : "none" }}
                  >
                    If there's an issue,{" "}
                    <a
                      href={article.url}
                      target="_blank"
                      aria-labelledby="articleLinkLabel"
                      rel="noreferrer"
                    >
                      launch the article in a new tab
                    </a>
                  </Typography>
                  <iframe
                    title={articleVisible ? article.title : ""}
                    src={articleUrlAsHttps}
                    height={articleVisible ? "100%" : "0px"}
                    width={articleVisible ? "100%" : "0px"}
                  />
                  {articleVisible ? null : <Bounce />}
                </ArticleContent>
              </>
            )}
          </ArticleDetailBody>
        </ResultCardWrapper>
      </ResultCard>
    </>
  );
};
