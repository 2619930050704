import styled from "@emotion/styled";
import { FontFamily } from "../../../css/styles.modulecss";

export const SummarizeBlock = styled.div`
  flex: 1;
  align-self: stretch;
  padding: 15px 15px 0px 0px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;

export const ModalTextArea = styled.textarea`
  border: none;
  border-radius: 10px;
  box-shadow: 1.5px 1.5px 2.5px 2.5px rgb(141, 139, 153, 0.4);
  resize: none;
  font-size: 18px;
  font-family: ${FontFamily.fontRoboto};
  height: 100%;
  width: 100%;
  color: #000;
  padding: 12px;
  background: #ece9fe;
  &:focus {
    border-color: #c8c5d8;
  }
`;
export const ButtonList = styled.ul`
  display: block;
  list-style-type: none;
  text-align: center;
  margin: 15px 0px;
  padding: 0;
`;

export const ButtonBlock = styled.li`
  display: inline-block;
  padding: 0 0px;
`;
