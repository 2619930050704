import React, { useEffect, useState } from "react";
import { UserStateContext } from "contexts/UserContext";
import TextField from "@mui/material/TextField";
import SchoolIcon from "@mui/icons-material/School";
import { ClassDetails } from "./ClassDetails";
import { Colors } from "css/styles.modulecss";
import { useHistory } from "react-router-dom";
import { handleOnErrorToastify } from "utility/toastify";

import {
  DashboardWrapper,
  ClassRoomListWrapper,
  ClassRoomListContainer,
  ClassRoom,
  NoSelectedClass,
  Header,
  CourseMessageBlock,
  CourseMessageContainer,
  InviteFormContainer,
  InviteButton,
  CourseMessageText,
  ExploreButton,
  ClassroomName,
  InviteCode,
  CourseWelcomeTitle,
  ExploreMessageContainer,
  ExploreButtonContainer,
  TopicMessageTextLink,
  InviteMessageText,
  CenteredCourseMessageText,
  FullWidthCourseMessageBlock,
  ClassDetailsContainer,
  IconContainer
} from "./UserDashboard.modulecss";

import {
  getClassroom,
  getStudentsClassroom,
  deleteStudent,
  joinClass
} from "services/classroom-service";
import { UserInfo } from "services/login-service";
import { ColorLogo } from "components/reusable/ColorLogo";

interface UserDashboardState {
  classrooms: any;
  classroomStudents: UserInfo[] | null;
  selectedClassroomInfo: any;
  inviteCode: string | null;
  classRoomID: string | null;
}
export const UserDashboard = () => {
  const history = useHistory();
  const goExplore = () => history.push("/user/explore");
  const goTimeline = () => history.push("/user/timeline");
  const userState = React.useContext(UserStateContext);
  const [state, setState] = useState<UserDashboardState>({
    classrooms: null,
    classroomStudents: null,
    selectedClassroomInfo: null,
    inviteCode: null,
    classRoomID: null
  });

  useEffect(() => {
    const fetchData = async () => {
      const result = await getClassroom();
      if (result.length !== 0) {
        const newStateData = {
          ...state,
          classrooms: result,
          selectedClassroomInfo: result[0].classroom,
          classRoomID: result[0].classroom.id
        };
        if (userState.register_as === "Educator") {
          const classroomStudents = await getStudentsClassroom(
            result[0].classroom.id
          );
          newStateData.classroomStudents = classroomStudents;
        }
        setState(newStateData);
      } else {
        setState({ ...state, classrooms: [] });
      }
    };
    fetchData();
  }, []);

  const joinClassHandler = async () => {
    const code = state.inviteCode;
    let result;
    try {
      if (code !== null) {
        await joinClass(code);
      }
      result = await getClassroom();
      setState({
        ...state,
        classrooms: result
      });
    } catch (error) {
      handleOnErrorToastify(error.error || error.message);
    }
  };

  const handleInviteCodeInput = (event) => {
    setState({ ...state, inviteCode: event.target.value });
  };

  const deleteHandler = async (classroom_id: number, student_id: number) => {
    if (state.classroomStudents) {
      try {
        await deleteStudent(classroom_id, student_id);
        const filteredStudents = state.classroomStudents.filter(
          (item) => item.id !== student_id
        );
        setState({
          ...state,
          classroomStudents: filteredStudents
        });
      } catch (error) {
        handleOnErrorToastify(error.error || error.message);
      }
    }
  };

  const activitiesLinks = (
    <div>
      <ExploreMessageContainer>
        <TopicMessageTextLink>
          <a
            href={"https://www.mucktracker.com/learning-resources.html"}
            target="_blank"
            style={{ color: Colors.black }}
            rel="noreferrer"
          >
            Mucktracker Learning Resources
          </a>
        </TopicMessageTextLink>
      </ExploreMessageContainer>
      <ExploreMessageContainer>
        <TopicMessageTextLink style={{ textDecoration: "underline", cursor: "pointer" }} onClick={goExplore}>
          Explore Mucktracker
        </TopicMessageTextLink>
      </ExploreMessageContainer >
    </div >
  );
  return (
    <DashboardWrapper>
      {userState.register_as === "Educator" ? (
        <>
          {state.classrooms === null ? (
            <div />
          ) : state.classrooms.length > 0 ? (
            <>
              <ClassRoomListWrapper>
                <Header>Your Classes</Header>
                <ClassRoomListContainer>
                  {state.classrooms.map((item, index) => (
                    <ClassRoom
                      key={index}
                      onClick={async () => {
                        const students = await getStudentsClassroom(
                          item.classroom.id
                        );
                        setState({
                          ...state,
                          classRoomID: item.classroom.id,
                          classroomStudents: students,
                          selectedClassroomInfo: item.classroom
                        });
                      }}
                      style={{
                        borderColor:
                          item.classroom.id === state.classRoomID
                            ? Colors.bright_blue
                            : Colors.white
                      }}
                    >
                      <IconContainer>
                        <SchoolIcon
                          style={{
                            fontSize: "40px",
                            height: "100%",
                            color:
                              item.classroom.id === state.classRoomID
                                ? Colors.bright_blue
                                : Colors.black
                          }}
                        />
                      </IconContainer>
                      <ClassDetailsContainer>
                        <ClassroomName>{item.classroom.name} </ClassroomName>
                        <InviteCode>
                          Invite Code: {item.classroom.invite_code}
                        </InviteCode>
                      </ClassDetailsContainer>
                    </ClassRoom>
                  ))}
                </ClassRoomListContainer>
                <FullWidthCourseMessageBlock>
                  {activitiesLinks}
                </FullWidthCourseMessageBlock>
              </ClassRoomListWrapper>
              {state.classroomStudents ? (
                <ClassDetails
                  classroomInformation={state.selectedClassroomInfo}
                  classroomStudents={state.classroomStudents}
                  deleteHandler={deleteHandler}
                />
              ) : (
                <NoSelectedClass>
                  Select a Classroom to view student roster
                </NoSelectedClass>
              )}
            </>
          ) : (
            <CourseMessageContainer>
              <CourseWelcomeTitle>
                <ColorLogo />
              </CourseWelcomeTitle>
              <CourseMessageBlock>
                <ExploreMessageContainer>
                  <CourseMessageText>
                    Start searching the news:
                  </CourseMessageText>
                  <ExploreButtonContainer>
                    <ExploreButton onClick={goExplore}>Canvas</ExploreButton>
                  </ExploreButtonContainer>
                </ExploreMessageContainer>
                <ExploreMessageContainer>
                  <CourseMessageText>
                    See the news displayed on a timeline:
                  </CourseMessageText>
                  <ExploreButtonContainer>
                    <ExploreButton onClick={goTimeline}>Timeline</ExploreButton>
                  </ExploreButtonContainer>
                </ExploreMessageContainer>
              </CourseMessageBlock>
              <CourseMessageBlock>
                <CenteredCourseMessageText>
                  {`To see Mucktracker lesson activities, suggested search topics, and other resources, visit our `}
                  <a
                    href={"https://www.mucktracker.com/learning-resources.html"}
                  >
                    Learning Resources
                  </a>
                  {` page.`}
                </CenteredCourseMessageText>
                <div style={{ marginBottom: 30 }} />
                <CenteredCourseMessageText>
                  {`To set up a class, contact us at `}
                  <a href="mailto:info@mucktracker.com">info@mucktracker.com</a>
                  {"."}
                </CenteredCourseMessageText>
              </CourseMessageBlock>
            </CourseMessageContainer>
          )}
        </>
      ) : null}
      {userState.register_as === "Student" ? (
        <>
          {state.classrooms !== null &&
            Object.keys(state.classrooms).length !== 0 ? (
            <CourseMessageContainer>
              <CourseMessageBlock>
                <CourseWelcomeTitle>
                  <ColorLogo />
                </CourseWelcomeTitle>
                <CourseMessageText>
                  {`You are enrolled in ${state.classrooms[0].classroom.name}.`}
                </CourseMessageText>
                <ExploreMessageContainer>
                  <CourseMessageText>
                    Start searching the news:
                  </CourseMessageText>
                  <ExploreButtonContainer>
                    <ExploreButton onClick={goExplore}>Canvas</ExploreButton>
                  </ExploreButtonContainer>
                </ExploreMessageContainer>
                <ExploreMessageContainer>
                  <CourseMessageText>
                    See the news displayed on a timeline:
                  </CourseMessageText>
                  <ExploreButtonContainer>
                    <ExploreButton onClick={goTimeline}>Timeline</ExploreButton>
                  </ExploreButtonContainer>
                </ExploreMessageContainer>
                <ExploreMessageContainer>
                  <CourseMessageText>
                    See Mucktracker learning resources:
                  </CourseMessageText>
                  <ExploreButtonContainer>
                    <ExploreButton
                      onClick={() =>
                        window.open(
                          "https://www.mucktracker.com/learning-resources.html",
                          "_blank"
                        )
                      }
                    >
                      Resources
                    </ExploreButton>
                  </ExploreButtonContainer>
                </ExploreMessageContainer>
              </CourseMessageBlock>
            </CourseMessageContainer>
          ) : (
            <CourseMessageContainer>
              <CourseWelcomeTitle>
                <ColorLogo />
              </CourseWelcomeTitle>
              <CourseMessageBlock>
                <ExploreMessageContainer>
                  <CourseMessageText>
                    Start searching the news:
                  </CourseMessageText>
                  <ExploreButtonContainer>
                    <ExploreButton onClick={goExplore}>Canvas</ExploreButton>
                  </ExploreButtonContainer>
                </ExploreMessageContainer>
                <ExploreMessageContainer>
                  <CourseMessageText>
                    See the news displayed on a timeline:
                  </CourseMessageText>
                  <ExploreButtonContainer>
                    <ExploreButton onClick={goTimeline}>Timeline</ExploreButton>
                  </ExploreButtonContainer>
                </ExploreMessageContainer>
                <ExploreMessageContainer>
                  <CourseMessageText>
                    See Mucktracker learning resources:
                  </CourseMessageText>
                  <ExploreButtonContainer>
                    <ExploreButton
                      onClick={() =>
                        window.open(
                          "https://www.mucktracker.com/learning-resources.html",
                          "_blank"
                        )
                      }
                    >
                      Resources
                    </ExploreButton>
                  </ExploreButtonContainer>
                </ExploreMessageContainer>
              </CourseMessageBlock>
              <CourseMessageBlock>
                <InviteMessageText>{`Please enter invite code to join course`}</InviteMessageText>
                <InviteFormContainer>
                  <TextField
                    id="outlined-basic"
                    placeholder="Invite Code"
                    value={state.inviteCode}
                    onChange={(e) => {
                      handleInviteCodeInput(e);
                    }}
                    inputProps={{ style: { textAlign: "center" } }}
                    size="medium"
                  />
                  <InviteButton onClick={joinClassHandler}>Join</InviteButton>
                </InviteFormContainer>
              </CourseMessageBlock>
            </CourseMessageContainer>
          )}
        </>
      ) : null}
    </DashboardWrapper>
  );
};
