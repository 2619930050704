import React, { useState, useEffect } from "react";
import {
  DragDropContext,
  DraggableLocation,
  Droppable
} from "react-beautiful-dnd";
import { ArticleDetailCard } from "./ArticleDetailCard";
import {
  dashboardContentStyles,
  DashboardWrapper,
  SearchResultWrapper,
  CanvasWrapper,
  SearchResultCard
} from "./Dashboard.modulecss";
import {
  saveArticle,
  ArticleData,
  postCanvas,
  getArticlesByCanvas,
  Canvas,
  updateArticlePosition,
  StoredArticle
} from "services/article-service";

import { SearchResults } from "./SearchResults";
import { CanvasComponent } from "./CanvasComponent";

import { Modal } from "@mui/material";

interface DashboardProps {
  articles: ArticleData[];
  fetching: boolean;
  isSearchPerformed: boolean;
  onSearch: (query: string, useBing: boolean) => void;
  searchValue: string;
  setSearchValue: (value: string | ((prevState: string) => string)) => void;
}

export const Dashboard = (props: DashboardProps) => {
  const classes = dashboardContentStyles();
  const [canvasArticles, setCanvasArticles] = useState<StoredArticle[] | null>(
    null
  );
  const [selectedArticle, setSelectedArticle] = useState<ArticleData | null>(
    null
  );
  const [canvas, setCanvas] = useState<Canvas | null>(null);
  const [articleDetailModal, setArticleDetailModal] = useState<boolean>(false);
  const [organizerOptionsModel, setOrganizerOptionsModel] =
    useState<boolean>(false);
  const [clearCanvasModal, setClearCanvasModal] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const canvasResp = await postCanvas();
      const articlesResp = await getArticlesByCanvas(canvasResp.data.id);
      setCanvasArticles(articlesResp);
      setCanvas(canvasResp.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setSelectedArticle(null);
  }, [props.fetching]);

  const handleCloseArticleDetail = async () => {
    await refreshArticles();
    setSelectedArticle(null);
    setArticleDetailModal(false);
  };

  const deleteCanvasArticle = (external_id: string) => {
    if (canvasArticles) {
      const newCanvasArticles = canvasArticles.filter(
        (article: ArticleData) => {
          return article.external_id !== external_id;
        }
      );
      setCanvasArticles(newCanvasArticles);
    }
  };

  const refreshArticles = async () => {
    if (canvas) {
      const newCanvasArticles = await getArticlesByCanvas(canvas.id);
      setCanvasArticles(newCanvasArticles);
    }
  };

  const getArticle = async (article: ArticleData) => {
    if (!canvas) {
      return;
    }
    try {
      article.canvas = canvas.id;
      const response = await saveArticle(article);
      setSelectedArticle(response.data);
      setArticleDetailModal(true);
      refreshArticles();
    } catch (error) {
      console.error("Error getting article: ", error);
    }
  };

  const updateArticlePos = async (
    articleId: number,
    newXPct: number,
    newYPct: number
  ) => {
    if (canvasArticles) {
      const data = {
        canvas_x_pct: newXPct,
        canvas_y_pct: newYPct
      };
      const newArticles = canvasArticles.map((a: ArticleData) => {
        return a.id === articleId ? { ...a, ...data } : a;
      });
      setCanvasArticles(newArticles);
      await updateArticlePosition(data, articleId);
    }
  };

  const expandFromSerp = async (article: ArticleData) => {
    article.canvas_x_pct = 0;
    article.canvas_y_pct = 0;
    await getArticle(article);
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const destination: DraggableLocation = result.destination;

    if (destination.droppableId === "detailCard") {
      const selectedArticleObj = props.articles.find(
        (obj) => obj.external_id === result.draggableId
      );

      if (selectedArticleObj && canvas && canvas.id) {
        try {
          selectedArticleObj.canvas = canvas.id;
          selectedArticleObj.canvas_x_pct = 0;
          selectedArticleObj.canvas_y_pct = 0;
          const response = await saveArticle(selectedArticleObj);
          selectedArticleObj.id = response.data.id;
        } catch (error) {
          console.error("Error storing article: ", error);
        }
        if (canvasArticles === null) {
          setCanvasArticles([selectedArticleObj]);
        } else {
          const newCanvasArticles = [...canvasArticles, selectedArticleObj];
          setCanvasArticles(newCanvasArticles);
        }
      }
    }
  };

  const handleOrganizerModelChange = () => {
    const modelState = organizerOptionsModel;
    if (clearCanvasModal) {
      setClearCanvasModal(false);
    }
    setOrganizerOptionsModel(!modelState);
  };

  const handleClearCanvasModal = () => {
    const modelState = clearCanvasModal;
    if (organizerOptionsModel) {
      setOrganizerOptionsModel(false);
    }
    setClearCanvasModal(!modelState);
  };

  const halfScreen = true; // TODO: if we scrap iframes, actually delete the code that uses this
  // selectedArticle != null &&
  // selectedArticle.source != null &&
  // HIDE_IFRAME_WHITELIST.has(selectedArticle.source.name);
  return (
    <DashboardWrapper>
      <main className={classes.content}>
        <SearchResultWrapper>
          <DragDropContext onDragEnd={onDragEnd}>
            <SearchResults
              articlesList={props.articles}
              fetching={props.fetching}
              isSearchPerformed={props.isSearchPerformed}
              onSelectArticle={expandFromSerp}
              canvasArticleList={canvasArticles}
              onSearch={props.onSearch}
              searchValue={props.searchValue}
              setSearchValue={props.setSearchValue}
            />
            <CanvasWrapper>
              <Droppable droppableId="detailCard">
                {(provided) => (
                  <SearchResultCard
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <CanvasComponent
                      aria-label="Article Stub Canvas"
                      handleOrganizerModelChange={handleOrganizerModelChange}
                      organizerOptionsModel={organizerOptionsModel}
                      canvasArticles={canvasArticles}
                      canvas={canvas}
                      deleteCanvasArticle={deleteCanvasArticle}
                      refreshArticles={refreshArticles}
                      onSelectArticle={getArticle}
                      updateArticlePosition={updateArticlePos}
                      clearCanvasModal={clearCanvasModal}
                      handleCanvasClearModal={handleClearCanvasModal}
                      articleList={props.articles}
                    />
                  </SearchResultCard>
                )}
              </Droppable>
            </CanvasWrapper>
          </DragDropContext>
        </SearchResultWrapper>
        {selectedArticle !== null ? (
          <Modal
            open={articleDetailModal}
            onClose={handleCloseArticleDetail}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <div
              className={classes.modalPlacement}
              style={{
                height: halfScreen ? "60%" : "95%"
              }}
            >
              <ArticleDetailCard
                aria-label="Article Detail Card"
                article={selectedArticle}
                refreshArticles={refreshArticles}
                closeArticleDetailCard={handleCloseArticleDetail}
                halfScreen={halfScreen}
              />
            </div>
          </Modal>
        ) : null}
      </main>
    </DashboardWrapper>
  );
};
