import {
  clearLocalStorage,
  setItemToLocalStorage,
  removeItemFromLocalStorage,
  getItemFromLocalStorage
} from "utility/browser-storage/local-storage";
import { postHttp, getHttp } from "./backend";
import {
  UserDispatch,
  LOGIN_ACTION,
  LOGOUT_ACTION
} from "contexts/UserContext";

export interface UserInfo {
  id: number;
  username: string;
  token: string;
  first_name: string;
  last_name: string;
  is_staff: boolean;
  last_login?: string;
}

const LoginRoutes = {
  loginUser: `/token-auth/`,
  getCurrentUser: `/current_user/`,
  createNewUser: `/users/`
};

interface LoginRequestParams {
  username: string;
  password: string;
  rememberMeChecked: boolean;
}

// Log in for existing user
export const signIn = async (
  dispatch: UserDispatch,
  data: LoginRequestParams
) => {
  const result = await postHttp(LoginRoutes.loginUser, data);
  if (result.token) {
    // Cache login artifacts locally
    const userInfo: UserInfo = result.user;
    setItemToLocalStorage("user", userInfo);
    setItemToLocalStorage("token", result.token);
    if (data && data.rememberMeChecked) {
      setItemToLocalStorage("remember-me", data, true);
    } else {
      removeItemFromLocalStorage("remember-me");
    }

    dispatch({
      type: LOGIN_ACTION,
      data: userInfo
    });
  }

  return result;
};

interface SignUpRequestParams {
  username: string;
  email: string;
  password: string;
  passwordConfirm: string;
}

// Create new user
export const signUp = async (data: SignUpRequestParams) => {
  return await postHttp(LoginRoutes.createNewUser, data);
};

export const tryLoginWithLocalToken = async (dispatch: UserDispatch) => {
  let userInfo: UserInfo | null = null;
  try {
    userInfo = await getHttp(LoginRoutes.getCurrentUser);
  } catch (err) {
    // not logged in
  }
  if (userInfo) {
    dispatch({
      type: LOGIN_ACTION,
      data: userInfo
    });
  } else {
    dispatch({ type: LOGOUT_ACTION });
    clearLocalStorage();
  }
};

export const getRememberMeValues = () => {
  const rememberMeValues: LoginRequestParams =
    getItemFromLocalStorage("remember-me", true) || undefined;
  return rememberMeValues;
};

export const logout = (dispatch: UserDispatch) => {
  removeItemFromLocalStorage("token");
  removeItemFromLocalStorage("user");
  dispatch({ type: LOGOUT_ACTION });
};
