import styled from "@emotion/styled";
import { Colors, FontFamily, FontSize } from "../../../css/styles.modulecss";

export const SearchIMG = styled.img`
  display: flex;
  width: 80px;
  padding: 15px;
  font-size: ${FontSize.largeText};
  font-weight: 600;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-family: ${FontFamily.fontRoboto};
  letter-spacing: 0.5px;
  color: ${Colors.black};
  pointer-events: none;
  @media (max-width: 1199px) {
    font-size: ${FontSize.smallText};
    max-width: 70px;
  }
`;

export const SourceText = styled.div`
  display: flex;
  max-width: 140px;
  padding: 5px;
  width: 100%;
  height: 80%;
  margin: 15px;
  font-size: ${FontSize.largeText};
  font-weight: 600;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-family: ${FontFamily.fontRoboto};
  letter-spacing: 0.5px;
  color: ${Colors.black};
  @media (max-width: 1199px) {
    font-size: ${FontSize.smallText};
    max-width: 70px;
  }
  word-break: break-word;
  border: 3px solid ${Colors.black};
`;
