import styled from "@emotion/styled";
import { Colors, FontFamily, FontSize } from "../../../css/styles.modulecss";

export const SearchListCard = styled.div`
  display: inline-flex;
  width: 30%;
  margin: 0 15px;
  flex-direction: column;
  @media (max-width: 991px) {
    width: 95%;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  border-radius: 15px;
  flex-direction: column;
  min-height: 10px;
  max-height: 93vh;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: ${FontSize.heading};
  color: gray;
  text-align: center;
`;
export const SearchLists = styled.div`
  display: flex;
  border-radius: 15px;
  flex-direction: column;
  min-height: 10px;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }
  &::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: #000000;
  }
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  @media (max-width: 991px) {
    max-height: 400px;
  }
`;

export const SearchList = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 5px 5px;
  border-bottom: 1px solid ${Colors.grey};
  cursor: pointer;
  border-radius: 0;
  background-color: ${Colors.white};
  :last-child {
    border-bottom: 0;
    border-radius: 0 0 15px 15px;
  }
  :first-child {
    border-radius: 15px 15px 0 0;
  }
  :hover {
    background-color: ${Colors.grey};
  }
`;

export const SearchIMG = styled.div`
  display: flex;
  max-width: 140px;
  width: 30%;
  font-size: ${FontSize.largeText};
  font-weight: 600;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-family: ${FontFamily.fontRoboto};
  letter-spacing: 0.5px;
  color: ${Colors.black};
  @media (max-width: 1199px) {
    font-size: ${FontSize.smallText};
    max-width: 70px;
  }
`;
export const SearchContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;
export const ContentContainer = styled.div`
  display: flex;
  width: 70%;
  flex-direction: column;
`;
export const ContentHead = styled.a`
  font-size: 20px;
  line-height: 1.1em;
  color: ${Colors.black};
  margin-top: 10px;
  margin-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: bold;
  font-family: ${FontFamily.fontRoboto};
  text-decoration: none;
  overflow: hidden;
  @media (max-width: 1199px) {
    font-size: 22px;
    line-height: 28px;
  }
  @media (max-width: 599px) {
    font-size: ${FontSize.largeText};
    line-height: 23px;
  }
  :hover {
    color: ${Colors.blue};
    text-decoration: underline;
  }
`;

export const DateTime = styled.p`
  font-size: ${FontSize.smallText};
  color: ${Colors.black};
  font-weight: normal;
  line-height: normal;
  margin: 0;
  margin-bottom: 5px;
  font-family: ${FontFamily.fontRoboto};
  letter-spacing: 0.2px;
  @media (max-width: 1199px) {
    ${FontSize.smallText};
  }
  @media (max-width: 599px) {
    font-size: 14px;
  }
`;
export const News = styled.p`
  overflow: hidden;
  font-size: ${FontSize.smallText};
  color: ${Colors.black};
  height: 100%;
  max-height: 4.8em;
  line-height: 1.2em;
  margin: 0;
  font-family: ${FontFamily.fontRoboto};
  letter-spacing: 0.2px;
  @media (max-width: 1199px) {
    font-size: ${FontSize.smallText};
  }
  @media (max-width: 599px) {
    font-size: 14px;
    font-family: ${FontFamily.fontSans};
  }
`;

export const ArticleLink = styled.a`
  font-size: ${FontSize.smallText};
  color: ${Colors.black};
  display: flex;
  align-self: flex-end;
  margin-right: 5px;
  margin-top: 5px;
  border-bottom: 1px solid #000;
  text-decoration: none;
`;

export const NoResultOnCanvas = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: ${FontSize.title};
  color: ${Colors.slate_gray};
  text-align: center;
  padding: 40px;
`;

export const NoResultFound = styled.span`
  font-size: ${FontSize.title};
  color: ${Colors.slate_gray};
  text-align: center;
  font-size: ${FontSize.largeText};
`;
