import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "react-activity/dist/react-activity.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { MuckApp } from "./components/MuckApp";
import { UserContextProvider } from "./contexts/UserContext";

const container = document.getElementById("root");

const root = createRoot(container!);

// Removing StrictMode to allow the Draggable component (react-draggable)
// to use findDOMNode, which is deprecated in StrictMode.
// The react draggable's README has more info about how to fix
// the issue with StrictMode on:
// https://github.com/react-grid-layout/react-draggable/blob/2c3aa84352ca8eaed42a9ac0b59a1187e7850b6a/README.md#L231-L243
root.render(
  // <React.StrictMode>
  <UserContextProvider>
    <ToastContainer />
    <MuckApp />
  </UserContextProvider>
  // </React.StrictMode>
);
