import React, { useEffect, useState } from "react";
import { ArticleData } from "services/article-service";
import {
  Label,
  LabelContainer,
  Tick,
  TickContainer,
  TimelineAxis,
  TimelineColumn,
  TimelineContainer,
  TimelineWrapper,
  ZoomLevelContainer,
  ZoomPeripheralsContainer,
  LoadingIndicator,
  LoadingContainer,
  EmptyStateText,
  SliderContainer,
  SliderRail,
  SliderRangeBar,
  SliderPaddle,
  TotalArticlesLabel,
  SliderRangeWidget,
  SliderPaddleContainer,
  SliderHandle,
  SliderPaddleLabel,
  EarliestBucketWarningIcon,
  EarliestBucketWarning,
  EarliestBucketWarningContainer,
  SearchContainer
} from "./Timeline.modulecss";
import moment, { Moment } from "moment";
import {
  BucketViz,
  RANGE_CONFIGS,
  generateBucketViz,
  getLabels,
  zoomLabel,
  getFlagConfig,
  FlagConfig,
  getStartForRange,
  getEarliestBucketStart,
  getTotalTimeRange
} from "./timeline-helper";
import { Flag } from "./Flag";
import Draggable, { DraggableEvent, DraggableData } from "react-draggable";
import { debounce } from "@mui/material";
import { RangeSelector } from "./RangeSelector";
import { SearchBox } from "../Dashboard/SearchBox";

interface TimelineProps {
  articles: ArticleData[];
  fetching: boolean;
  isSearchPerformed: boolean;
  onSearch: (query: string, useBing: boolean) => void;
  searchValue: string;
  setSearchValue: (value: string | ((prevState: string) => string)) => void;
}

enum ANIMATION_STAGE {
  COMPLETE = "COMPLETE",
  CLEARING = "CLEARING",
  SLIDING = "SLIDING"
}

interface TimelineState {
  animationStage: ANIMATION_STAGE;
  bucketViz: BucketViz[];
  tickLabels: string[];
  range: string | null;
  maxRange: string | null;
  startDate: moment.Moment | null;
  sliderStartDate: moment.Moment | null;
  showSearchLimitWarning: boolean;
}

const MAX_HEIGHT = 200; //determines the height of a single article, and determines how many articles make up 100% of a column.
const MAX_ARTICLES_FROM_API = 200;

export const Timeline = (props: TimelineProps) => {
  const [state, setState] = useState<TimelineState>({
    animationStage: ANIMATION_STAGE.COMPLETE,
    bucketViz: [],
    tickLabels: [],
    range: null,
    maxRange: null,
    startDate: null,
    sliderStartDate: null,
    showSearchLimitWarning: false
  });
  useEffect(() => {
    if (state.animationStage === ANIMATION_STAGE.SLIDING) {
      setState({ ...state, animationStage: ANIMATION_STAGE.COMPLETE });
    } else if (state.animationStage === ANIMATION_STAGE.CLEARING) {
      setState({ ...state, animationStage: ANIMATION_STAGE.SLIDING });
    }
  }, [state.animationStage]);

  useEffect(() => {
    if (props.articles === null || props.articles.length === 0) {
      return;
    }
    // Articles come in with the newest article first
    const articleRangeStart = moment(
      props.articles[props.articles.length - 1].published_at
    )
      .utc(true)
      .local()
      .startOf("day"); //oldest article
    const articleRangeEnd = moment(props.articles[0].published_at)
      .utc(true)
      .local()
      .endOf("day"); //most recent article
    const allArticlesDurationDays = moment
      .duration(articleRangeEnd.diff(articleRangeStart))
      .as("days");

    let maxRange, initialRange;
    if (allArticlesDurationDays < 1) {
      //12 buckets, 2 hours each
      maxRange = "DAY";
      initialRange = "DAY";
    } else if (allArticlesDurationDays >= 1 && allArticlesDurationDays < 2) {
      maxRange = "TWODAY";
      initialRange = "DAY";
    } else if (allArticlesDurationDays >= 2 && allArticlesDurationDays < 4) {
      //6 buckets, half day each
      maxRange = "HALFWEEK";
      initialRange = "TWODAY";
    } else if (allArticlesDurationDays >= 4 && allArticlesDurationDays < 7) {
      //7 buckets, 1 day each
      maxRange = "WEEK";
      initialRange = "HALFWEEK";
    } else if (allArticlesDurationDays >= 7 && allArticlesDurationDays < 32) {
      // 8 buckets, 4 days each
      maxRange = "MONTH";
      initialRange = "WEEK";
    } else if (allArticlesDurationDays >= 32 && allArticlesDurationDays < 91) {
      //10 buckets, 9 days each
      maxRange = "QUARTERYEAR";
      initialRange = "MONTH";
    } else if (allArticlesDurationDays >= 91 && allArticlesDurationDays < 360) {
      //12 buckets, 1 month each
      maxRange = "YEAR";
      initialRange = "QUARTERYEAR";
    } else if (allArticlesDurationDays >= 360) {
      //12 buckets, 2 month each
      maxRange = "TWOYEAR";
      initialRange = "YEAR";
    }
    // Start at the end date, zoomed in one level
    const config = RANGE_CONFIGS[initialRange];
    const startDate = getStartForRange(config);
    const bucketViz = generateBucketViz(props.articles, config, startDate);
    const tickLabels = getLabels(config, startDate);

    setState({
      ...state,
      tickLabels,
      bucketViz,
      range: initialRange,
      maxRange,
      startDate,
      sliderStartDate: startDate,
      showSearchLimitWarning: false
    });
  }, [props.articles]);

  const selectRange = (range: string) => {
    if (props.articles.length === 0 || !state.range || !state.startDate) {
      return;
    }

    const oldRangeConfig = RANGE_CONFIGS[state.range];
    const oldStartDate = state.startDate.clone();
    const newRangeConfig = RANGE_CONFIGS[range];
    const rangeStart = getStartForRange(newRangeConfig);

    // TODO: this is duplicated with the slide range function, need to refactor
    const newBucketViz = generateBucketViz(
      props.articles,
      newRangeConfig,
      rangeStart,
      oldRangeConfig,
      oldStartDate
    );
    for (const i in newBucketViz) {
      const bucket = newBucketViz[i];
      // The prevHeight of each bucket should be the height of the bucket at the same position
      // in the bucketViz before zooming
      if (
        bucket.prevPosition !== undefined &&
        bucket.prevPosition < state.bucketViz.length
      ) {
        bucket.prevHeight = state.bucketViz[bucket.prevPosition].height;
      }
    }
    const tickLabels = getLabels(newRangeConfig, rangeStart);

    setState({
      ...state,
      bucketViz: newBucketViz,
      tickLabels,
      range,
      startDate: rangeStart,
      animationStage: ANIMATION_STAGE.CLEARING,
      sliderStartDate: rangeStart,
      showSearchLimitWarning: false
    });
  };

  const slideFrameStateless = (
    newStartDate: Moment,
    articles: ArticleData[],
    _state: TimelineState
  ) => {
    if (_state.range === null || _state.startDate === null) {
      return;
    }
    const rangeConfig = RANGE_CONFIGS[_state.range];

    // TODO: this is duplicated with the zoom function, need to refactor
    const newBucketViz = generateBucketViz(
      articles,
      rangeConfig,
      newStartDate,
      rangeConfig,
      _state.startDate
    );
    for (const i in newBucketViz) {
      const bucket = newBucketViz[i];
      // The prevHeight of each bucket should be the height of the bucket at the same position
      // in the bucketViz before zooming
      if (
        bucket.prevPosition !== undefined &&
        bucket.prevPosition < _state.bucketViz.length
      ) {
        bucket.prevHeight = _state.bucketViz[bucket.prevPosition].height;
      }
    }
    const tickLabels = getLabels(rangeConfig, newStartDate);

    setState({
      ..._state,
      bucketViz: newBucketViz,
      tickLabels,
      startDate: newStartDate,
      sliderStartDate: newStartDate,
      animationStage: ANIMATION_STAGE.CLEARING,
      showSearchLimitWarning: false
    });
  };

  const nonEmptyBuckets = state.bucketViz.filter((value) => {
    return value.articles.length > 0;
  });

  let flagConfigs: FlagConfig[] = [];
  if (state.range) {
    const rangeConfig = RANGE_CONFIGS[state.range];
    flagConfigs = getFlagConfig(nonEmptyBuckets, rangeConfig);
  }
  const flags = flagConfigs.map((flagConfig: FlagConfig, index) => {
    return <Flag flagConfig={flagConfig} key={index} />;
  });

  let startPct = 0;
  let sliderStartPct = 0;
  let endPct = 100;
  if (state.range && state.startDate && state.sliderStartDate) {
    const rangeConfig = RANGE_CONFIGS[state.range];
    const earliestBucketStart = getEarliestBucketStart(
      props.articles,
      rangeConfig
    );
    const totalTimeRange = getTotalTimeRange(earliestBucketStart, rangeConfig);
    startPct =
      (100 * state.startDate.diff(earliestBucketStart, rangeConfig.rangeUnit)) /
      totalTimeRange;
    sliderStartPct =
      (100 *
        state.sliderStartDate.diff(
          earliestBucketStart,
          rangeConfig.rangeUnit
        )) /
      totalTimeRange;
    endPct =
      (100 *
        state.startDate
          .clone()
          .add(
            rangeConfig.bucketRange * rangeConfig.numBuckets,
            rangeConfig.rangeUnit
          )
          .diff(earliestBucketStart, rangeConfig.rangeUnit)) /
      totalTimeRange;
    const zoomedFullyOut =
      state.range !== null && state.range === state.maxRange;
    startPct = zoomedFullyOut || startPct < 0 ? 0 : startPct;
    sliderStartPct = zoomedFullyOut || sliderStartPct < 0 ? 0 : sliderStartPct;
    endPct = zoomedFullyOut || endPct > 100 ? 100 : endPct;
  }

  const totalBarWidth =
    document.getElementById("timelineaxis")?.offsetWidth ?? 0;

  const debouncedUpdate = React.useCallback(
    debounce(slideFrameStateless, 100),
    []
  );
  const handleDragSlider = (e: DraggableEvent, ui: DraggableData) => {
    if (state.range) {
      const rangeConfig = RANGE_CONFIGS[state.range];
      const earliestBucketStart = getEarliestBucketStart(
        props.articles,
        rangeConfig
      );
      const totalTimeRange = getTotalTimeRange(
        earliestBucketStart,
        rangeConfig
      );
      // TODO: this is "snap-to" logic, we could move this into the helper
      const timeSinceInitialStart = (ui.x / totalBarWidth) * totalTimeRange;
      const unsnappedNewStartDate = earliestBucketStart
        .clone()
        .add(timeSinceInitialStart, rangeConfig.rangeUnit);

      const endOfLastBucket = moment().local().endOf(rangeConfig.rangeUnit);
      const newStartDate = endOfLastBucket.clone();

      while (newStartDate.diff(unsnappedNewStartDate) > 0) {
        // Subtract one bucket at a time until we find the right date
        newStartDate.subtract(rangeConfig.bucketRange, rangeConfig.rangeUnit);
      }

      setState({ ...state, sliderStartDate: newStartDate });
      debouncedUpdate(newStartDate, props.articles, state);
    }
  };

  return (
    <>
      <TimelineWrapper>
        <SearchContainer>
          <SearchBox
            onSearch={props.onSearch}
            searchValue={props.searchValue}
            setSearchValue={props.setSearchValue}
          />
        </SearchContainer>
        <ZoomPeripheralsContainer>
          {props.articles.length !== 0 &&
          !props.fetching &&
          state.range !== null &&
          state.maxRange !== null ? (
            <>
              <ZoomLevelContainer id="timelineRangeLabel">
                {"Range:"} {zoomLabel(state.range)}
                <TotalArticlesLabel>
                  {`(${
                    props.articles.length
                  } most recent articles from ${moment(
                    props.articles[props.articles.length - 1].published_at
                  )
                    .utc(true)
                    .local()
                    .format("MM/DD/YYYY")} to ${moment(
                    props.articles[0].published_at
                  )
                    .utc(true)
                    .local()
                    .format("MM/DD/YYYY")})`}
                </TotalArticlesLabel>
              </ZoomLevelContainer>
              <RangeSelector
                selectedRangeName={state.range}
                onSelectRange={selectRange}
                maxRange={state.maxRange}
                aria-labelledby="timelineRangeLabel"
              />
            </>
          ) : null}
        </ZoomPeripheralsContainer>
        <TimelineContainer>
          {props.fetching ? (
            <LoadingContainer>
              <LoadingIndicator>
                <div></div>
                <div></div>
                <div></div>
              </LoadingIndicator>
            </LoadingContainer>
          ) : props.articles.length === 0 && props.isSearchPerformed ? (
            <EmptyStateText>
              Looks like we didn't find any articles. Try another search!
            </EmptyStateText>
          ) : props.articles.length === 0 ? (
            <EmptyStateText>
              {`Search for something to see a timeline of the ${MAX_ARTICLES_FROM_API} most recent articles!`}
            </EmptyStateText>
          ) : (
            <>
              <TimelineAxis id="timelineaxis">
                {flags}
                {
                  // Before animation starts, clear screen
                  state.animationStage === ANIMATION_STAGE.CLEARING ? (
                    <div />
                  ) : state.animationStage === ANIMATION_STAGE.SLIDING ? (
                    <>
                      {
                        // At the beginning of animation, put new buckets in previous spots
                        state.bucketViz.map((viz: BucketViz, index: number) => {
                          const position = viz.prevPosition
                            ? viz.prevPosition
                            : 0;
                          const height = viz.prevHeight ? viz.prevHeight : 0;
                          const widthPct = viz.prevWidthPct
                            ? viz.prevWidthPct
                            : 0;
                          return (
                            <TimelineColumn
                              key={index}
                              columnPosition={`${position * 100}%`}
                              columnHeight={`${
                                (100 / MAX_HEIGHT) *
                                Math.min(height, MAX_HEIGHT)
                              }%`}
                              columnWidth={widthPct.toString() + "%"}
                            />
                          );
                        })
                      }
                    </>
                  ) : (
                    <>
                      {state.bucketViz.map((viz: BucketViz, index: number) => {
                        return (
                          <TimelineColumn
                            key={index}
                            columnPosition={`${viz.position * 100}%`}
                            columnHeight={`${
                              (100 / MAX_HEIGHT) *
                              Math.min(viz.height, MAX_HEIGHT)
                            }%`}
                            columnWidth={viz.widthPct.toString() + "%"}
                            style={{ zIndex: viz.hasFirstArticle ? 2 : 1 }}
                          >
                            {viz.hasFirstArticle ? (
                              <EarliestBucketWarningContainer
                                onClick={() => {
                                  setState({
                                    ...state,
                                    showSearchLimitWarning:
                                      !state.showSearchLimitWarning
                                  });
                                }}
                              >
                                <EarliestBucketWarningIcon>
                                  !
                                </EarliestBucketWarningIcon>
                                {state.showSearchLimitWarning ? (
                                  <EarliestBucketWarning>
                                    {props.articles.length ===
                                    MAX_ARTICLES_FROM_API
                                      ? "This is the earliest article available in the 200 most recent articles. If you select a more specific search term, you may be able to see articles from an earlier date."
                                      : "This is the earliest search result available. Articles before this are not captured by Mucktracker and not available for search."}
                                  </EarliestBucketWarning>
                                ) : null}
                              </EarliestBucketWarningContainer>
                            ) : null}
                          </TimelineColumn>
                        );
                      })}
                    </>
                  )
                }
              </TimelineAxis>
              <TickContainer>
                {state.animationStage === ANIMATION_STAGE.CLEARING
                  ? null
                  : state.bucketViz.map((value, index) => {
                      return (
                        <Tick
                          opacity={
                            state.animationStage === ANIMATION_STAGE.SLIDING
                              ? 0
                              : 1
                          }
                          key={index}
                          tickWidth={value.widthPct.toString() + "%"}
                        ></Tick>
                      );
                    })}
              </TickContainer>
              <LabelContainer>
                {state.animationStage === ANIMATION_STAGE.CLEARING
                  ? null
                  : state.bucketViz.map((value, index) => {
                      return (
                        <Label
                          opacity={
                            state.animationStage === ANIMATION_STAGE.SLIDING
                              ? 0
                              : 1
                          }
                          key={index}
                          tickWidth={value.widthPct.toString() + "%"}
                        >
                          {state.tickLabels[index]}
                        </Label>
                      );
                    })}
              </LabelContainer>
              {state.sliderStartDate ? (
                <>
                  <SliderContainer id="slidercontainer">
                    <SliderRail />
                    <Draggable
                      position={{
                        x: (sliderStartPct * totalBarWidth) / 100,
                        y: 0
                      }}
                      axis="x"
                      bounds="#slidercontainer"
                      key={"sliderdraggable"}
                      onDrag={handleDragSlider}
                      disabled={
                        state.range !== null && state.range === state.maxRange
                      }
                      aria-label="timelineDateRangeSlider"
                    >
                      <SliderRangeWidget
                        rangeStartPct={startPct}
                        rangeEndPct={endPct}
                      >
                        <SliderRangeBar />
                        {state.range !== null &&
                        state.range === state.maxRange ? null : (
                          <SliderPaddleContainer>
                            <SliderPaddle />
                            <SliderHandle />
                            <SliderPaddle />
                          </SliderPaddleContainer>
                        )}
                      </SliderRangeWidget>
                    </Draggable>
                  </SliderContainer>
                  {state.range !== null &&
                  state.range === state.maxRange ? null : (
                    <SliderPaddleLabel startPct={sliderStartPct}>
                      {state.sliderStartDate &&
                      state.startDate &&
                      state.sliderStartDate !== state.startDate
                        ? state.sliderStartDate
                            .clone()
                            .utc(true)
                            .local()
                            .format("MM/DD/YYYY")
                            .toString()
                        : ""}
                    </SliderPaddleLabel>
                  )}
                </>
              ) : null}
            </>
          )}
        </TimelineContainer>
      </TimelineWrapper>
    </>
  );
};
