/** @jsxRuntime classic */
/** @jsx jsx */

import React from "react";
import {
  AppBar,
  Divider,
  MenuItem,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList,
  Checkbox
} from "@mui/material";
import { UserDispatchContext, UserStateContext } from "contexts/UserContext";
import { useLocation, NavLink } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { Logo } from "../../reusable/Logo";
import { jsx } from "@emotion/react";
import {
  navbarStyles,
  AvatarWrapper,
  RightWrapper,
  LeftWrapper
} from "./Navbar.modulecss";
import { logout } from "services/login-service";

export const Navbar = () => {
  const css = navbarStyles;
  const location = useLocation();
  const [useBing, setUseBing] = React.useState(false);
  const userState = React.useContext(UserStateContext);
  const userDispatch = React.useContext(UserDispatchContext);
  const logoutUser = () => {
    logout(userDispatch);
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const isExplorePage = location.pathname === "/user/explore";

  return (
    <div>
      <AppBar position="fixed" css={css.CommonHeader}>
        <LeftWrapper>
          <Logo />
          {location.pathname === "/user/dashboard" ? (
            <div />
          ) : (
            <NavLink
              css={css.Link}
              key={3}
              to={isExplorePage ? "/user/timeline" : "/user/explore"}
            >
              {`${isExplorePage ? "Timeline" : "Canvas"}`}
            </NavLink>
          )}
        </LeftWrapper>
        <RightWrapper>
          {userState.is_staff ? (
            <Checkbox
              value={useBing}
              onClick={() => {
                setUseBing(!useBing);
              }}
            />
          ) : null}
          <AvatarWrapper>
            <Divider orientation="vertical" flexItem />
            <Button
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              css={css.ProfileButton}
              endIcon={<ExpandMoreIcon />}
            >
              {`${userState.first_name} ${userState.last_name}`}
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom"
                  }}
                >
                  <Paper>
                    {/* @ts-ignore */}
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem onClick={logoutUser}>Logout</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </AvatarWrapper>
        </RightWrapper>
      </AppBar>
    </div>
  );
};
export default Navbar;
