import React, { useEffect } from "react";

import { Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import Autocomplete from "@mui/lab/Autocomplete";
import { CommonHtmlElement } from "../../../css/styles.modulecss";
import {
  modalContentStyles,
  TagsSubtitle,
  TagsTitle,
  AddTagButtonContainer,
  AddTagButton,
  TagIcon,
  IconButton,
  ButtonText
} from "./AddTag.modulecss";

import { getUniqueTagsForUser } from "services/article-service";

interface TagState {
  tagValue: string | undefined;
  tagInputValue: string | undefined;
  tagOptions: string[];
}

interface TagProps {
  addTags: (string) => void;
  tagModalState: boolean;
  handleClose: () => void;
  handleOpen: () => void;
}

export default function AddTag(props: TagProps) {
  const [state, setState] = React.useState<TagState>({
    tagValue: "",
    tagInputValue: "",
    tagOptions: []
  });

  const fetchTags = async () => {
    const uniqueTags = await getUniqueTagsForUser();
    setState({ ...state, tagOptions: uniqueTags });
  };

  useEffect(() => {
    if (props.tagModalState === true) fetchTags();
  }, [props.tagModalState]);

  const classes = modalContentStyles();

  const addHandler = async () => {
    const tagName = state.tagInputValue;
    setState({ ...state, tagValue: "", tagInputValue: "" });
    props.addTags(tagName);
    props.handleClose();
  };

  return (
    <>
      <AddTagButtonContainer>
        <AddTagButton onClick={props.handleOpen}>
          Add tags
          <TagIcon>
            <IconButton>
              <ButtonText>{String.fromCharCode(10006)}</ButtonText>
            </IconButton>
          </TagIcon>
        </AddTagButton>
      </AddTagButtonContainer>

      <Dialog
        open={props.tagModalState}
        onClose={props.handleClose}
        classes={{
          container: classes.modalPlacement,
          paper: classes.dialogPaper
        }}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <TagsTitle>Tags</TagsTitle>
        <TagsSubtitle id="addTagLabel">Add a new tag</TagsSubtitle>
        <DialogContent>
          <Autocomplete
            aria-labelledby="addTagLabel"
            id="addTagInput"
            freeSolo
            value={state.tagValue}
            onChange={(event: any, newValue: string) => {
              setState({ ...state, tagValue: newValue });
            }}
            inputValue={state.tagInputValue}
            onInputChange={(event: any, newValue: string) => {
              setState({ ...state, tagInputValue: newValue });
            }}
            disableClearable
            options={state.tagOptions}
            style={{ display: "flex", width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
          <DialogActions classes={{ spacing: classes.buttonPlacement }}>
            <CommonHtmlElement.CommonButton onClick={addHandler}>
              Add
            </CommonHtmlElement.CommonButton>
            <CommonHtmlElement.CommonButton onClick={props.handleClose}>
              Cancel
            </CommonHtmlElement.CommonButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
