import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Colors, FontFamily } from "../../../css/styles.modulecss";

const navBarLinkAndButtonStyles = css({
  textDecoration: "none",
  fontFamily: `${FontFamily.fontSans}`,
  display: "flex",
  textTransform: "capitalize",
  border: "0",
  padding: "10px 20px",
  lineHeight: "20px",
  fontSize: "16px",
  background: "transparent",
  cursor: "pointer",
  color: `${Colors.light_purple}`,
  "&:hover": {
    textDecoration: "underline",
    textUnderlineOffset: "4px"
  }
});

export const navbarStyles = {
  CommonHeader: css({
    "&.MuiAppBar-root": {
      backgroundColor: `${Colors.dark_purple}`,
      minHeight: "50px",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "space-between",
      boxShadow: "none"
    }
  }),

  // Tools Dropdown buttons
  itemtext: css({
    "&.MuiListItemText-root": {
      "& span": {
        fontFamily: FontFamily.fontSans,
        fontSize: "15px",
        "&:hover": {
          color: Colors.dark_cream
        }
      }
    }
  }),
  itemtextNested: css({
    "&.MuiListItemText-root": {
      "& span": {
        fontFamily: FontFamily.fontSans,
        fontSize: "13px",
        "&:hover": {
          color: Colors.dark_cream
        }
      }
    }
  }),

  Link: css({
    ...navBarLinkAndButtonStyles
  }),

  ProfileButton: css({
    "&.MuiButton-root": {
      ...navBarLinkAndButtonStyles,
      "&:focus": {
        outline: "none"
      }
    }
  }),

  menuli: css({
    "&.MuiListItem-root": {
      "@media(max-width:1400px)": {
        padding: "3px 10px"
      }
    }
  }),
  menuliNested: css({
    "&.MuiListItem-root": {
      "@media(max-width:1400px)": {
        padding: "1px 1px"
      }
    }
  })
};

const LeftWrapper = styled.div`
  display: flex;
  color: ${Colors.white};
  margin-right: 15px;
  align-items: center;
`;

const RightWrapper = styled.div`
  display: flex;
  color: ${Colors.white};
  margin-right: 15px;
  align-items: center;
`;

const AvatarWrapper = styled.div`
  display: flex;
`;

export { AvatarWrapper, RightWrapper, LeftWrapper };
