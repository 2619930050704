import React, { useEffect } from "react";
import { Dialog, Tooltip } from "@mui/material";
import {
  AllTags,
  TagsContainer,
  TagContainer,
  TagText,
  DeleteButton,
  ExpandTags,
  ExpandTagsContainer,
  modalContentStyles,
  DialogTagContainer,
  ButtonText
} from "./Tags.modulecss";

interface TagsState {
  modalOpen: boolean;
  tags: string[];
}
interface TagsProps {
  tags: string[];
  deleteTags: (string) => void;
}
export default function Tags(props: TagsProps) {
  const [state, setState] = React.useState<TagsState>({
    modalOpen: false,
    tags: props.tags
  });

  const classes = modalContentStyles();

  const deleteHandler = (e) => {
    props.deleteTags(e);
  };

  const handleClickOpen = () => {
    setState({ ...state, modalOpen: true });
  };

  const handleClose = () => {
    setState({ ...state, modalOpen: false });
  };

  useEffect(() => {
    setState({ ...state, tags: props.tags });
  }, [props.tags]);

  const firstTags = state.tags.slice(0, 3);

  return (
    <>
      <TagsContainer onClick={handleClickOpen}>
        {firstTags.map((item: string, index) => (
          <TagContainer key={index}>
            <TagText>{item}</TagText>
            <DeleteButton
              onClick={(e) => {
                e.stopPropagation();
                deleteHandler(item);
              }}
              aria-label="Delete Tag"
            >
              <ButtonText>{String.fromCharCode(10006)}</ButtonText>
            </DeleteButton>
          </TagContainer>
        ))}
        {state.tags.length > 3 && (
          <ExpandTagsContainer>
            <ExpandTags onClick={handleClickOpen}>{`+${
              state.tags.length - 3
            } More Tags`}</ExpandTags>
          </ExpandTagsContainer>
        )}
      </TagsContainer>
      <Dialog
        open={state.modalOpen}
        onClose={handleClose}
        classes={{
          container: classes.modalPlacement,
          paper: classes.dialogPaper
        }}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <AllTags>
          {state.tags.map((item: string, index) => (
            <Tooltip
              key={index}
              title={`${item}`}
              classes={{
                tooltip: classes.customWidth,
                arrow: classes.arrowStyles
              }}
              arrow
            >
              <DialogTagContainer>
                <TagText>{item}</TagText>
                <DeleteButton onClick={() => deleteHandler(item)}>
                  <ButtonText>{"X"}</ButtonText>
                </DeleteButton>
              </DialogTagContainer>
            </Tooltip>
          ))}
        </AllTags>
      </Dialog>
    </>
  );
}
