import styled from "@emotion/styled";
import { Colors, FontFamily, FontSize } from "../../../css/styles.modulecss";
import { Link } from "react-router-dom";
import { makeStyles } from "mui-styles";

export const classDetailsContentStyles = makeStyles(() => ({
  modalPlacement: {
    width: "30%",
    height: "25%",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "30",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }
}));

export const DashboardWrapper = styled.div`
  border-color: ${Colors.dark_grey};
  border-style: solid;
  border-width: 1px;
  background-color: ${Colors.white};
  width: 55%;
  height: calc(100vh - 200px);
  border-radius: 15px;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 20px;
    height: 14px;
  }
  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: ${Colors.black};
  }
`;

export const ClassHeader = styled.div`
  font-size: ${FontSize.heading};
  font-weight: bold;
  color: ${Colors.black};
  padding-right: 20px;
  margin-bottom: 3px;
  font-family: ${FontFamily.fontRoboto};
  letter-spacing: 0.3px;
`;

export const StudentItemList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;
export const StudentItem = styled.div`
  display: flex;
  border-radius: 15px;
  margin-bottom: 5px;
  padding: 5px;
  flex-direction: row;
  border: 1px solid ${Colors.black};
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const RightButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ViewCanvasButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${FontSize.largeText};
  border: 1px solid ${Colors.black};
  color: ${Colors.black};
  text-decoration: none;
  padding: 10px;
  height: 20px;
  margin-right: 10px;
  border-radius: 10px;
  cursor: pointer;
`;

export const DeleteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.black};
  border: 1px solid ${Colors.black};
  font-family: ${FontFamily.fontRoboto};
  font-size: ${FontSize.largeText};
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
`;

export const StudentInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const StudentUsername = styled.div`
  font-size: ${FontSize.smallText};
  color: ${Colors.black};
  font-weight: bold;
  font-family: ${FontFamily.fontRoboto};
`;

export const StudentLastActive = styled.div`
  font-size: 13px;
  color: ${Colors.black};
  font-weight: normal;
  font-family: ${FontFamily.fontRoboto};
  margin-left: 5px;
`;

export const NoStudent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: ${FontSize.title};
  color: ${Colors.black};
  text-align: center;
  padding: 40px;
`;

export const DeleteConfirmationText = styled.div`
  font-size: ${FontSize.title};
  text-align: center;
  margin-bottom: 15px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;
