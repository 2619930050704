import React from "react";
import { Input, InputAdornment, FormControl } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { SearchBoxWrapper, searchBarStyles } from "./SearchBox.modulecss";
import { Colors } from "../../../css/styles.modulecss";

interface SearchBoxProps {
  onSearch: (query: string, useBing: boolean) => void;
  searchValue: string;
  setSearchValue: (value: string | ((prevState: string) => string)) => void;
}

export const SearchBox = (props: SearchBoxProps) => {
  const classes = searchBarStyles();
  const { searchValue, setSearchValue } = props;
  const useBing = false;

  const handleSearchValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const handleSearch = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      props.onSearch(searchValue, useBing);
    }
  };

  return (
    <SearchBoxWrapper>
      <FormControl className={classes.searchbar}>
        <Input
          id="input-with-icon-adornment"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon style={{ fill: Colors.bluish_purple }} />
            </InputAdornment>
          }
          value={searchValue}
          onChange={handleSearchValueChange}
          placeholder="search the news"
          onKeyDown={handleSearch}
        />
      </FormControl>
      <FormControl className={classes.searchbarMobile}>
        <Input
          id="input-with-icon-adornment"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon style={{ fill: Colors.bluish_purple }} />
            </InputAdornment>
          }
          value={searchValue}
          onChange={handleSearchValueChange}
          placeholder="search the news"
          onKeyDown={handleSearch}
        />
      </FormControl>
    </SearchBoxWrapper>
  );
};

export default SearchBox;
