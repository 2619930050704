import { getHttp, putHttp, deleteHttp } from "./backend";

const ClassroomRoutes = {
  classroomView: `/api/classroom/classrooms/`
};

export interface ClassroomData {
  external_id: string | null;
  id: string;
  invite_code: string;
  name: string;
}

export interface ClassroomObject {
  classroom: ClassroomData;
}

export const getClassroom = async () => {
  const response = await getHttp(ClassroomRoutes.classroomView);
  return response.data;
};
export const getStudentsClassroom = async (classroom_id: string) => {
  const response = await getHttp(`/api/classroom/${classroom_id}/students/`);
  return response.data.map((d) => d.student);
};
export const getStudent = async (student_id: number) => {
  const response = await getHttp(`/api/classroom/student/${student_id}`);
  return response.data;
};

export const deleteStudent = async (
  classroom_id: number,
  student_id: number
) => {
  return await deleteHttp(
    `/api/classroom/${classroom_id}/student/${student_id}/`
  );
};

export const joinClass = async (code: string) => {
  const data = { invite_code: code };
  return await putHttp(`/api/classroom/invite-code/`, data);
};
