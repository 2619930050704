import styled from "@emotion/styled";
import { Colors, FontSize } from "../../../css/styles.modulecss";

export const RangeTabsContainer = styled.div`
  display: flex;
`;

interface RangeTabParams {
  selected?: boolean;
  disabled?: boolean;
}

export const RangeTab = styled.button<RangeTabParams>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  font-size: ${FontSize.largeText};
  font-weight: ${(props) =>
    !props.disabled && props.selected ? "bold" : "normal"};
  color: ${(props) => (props.disabled ? Colors.light_grey : Colors.dark_grey)};
  background: none;
  border: none;
  border-bottom: 1px solid
    ${(props) =>
      !props.disabled && props.selected ? "black" : Colors.light_grey};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;
